const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const formatDate = (date=null, givenOptions=null) => {
	/*
	const allOptions = {
		weekday: [ "narrow", "short", "long" ],
		era: [ "narrow", "short", "long" ],
		year: [ "2-digit", "numeric" ],
		month: [ "2-digit", "numeric", "narrow", "short", "long" ],
		day: [ "2-digit", "numeric" ],
		hour: [ "2-digit", "numeric" ],
		minute: [ "2-digit", "numeric" ],
		second: [ "2-digit", "numeric" ],
		timeZoneName: [ "short", "long" ]
	};
	*/
	let options = {}, functionName = "toLocaleDateString";
	if (typeof givenOptions !== 'object') {
		switch (givenOptions) {
			case "full":
				options = {
					weekday: "long",
					day: "2-digit",
					month: "long",
					year: "numeric",
					hour: '2-digit',
					minute: '2-digit'
				};
				break;
			case "short":
				options = {
					hour: '2-digit',
					minute: '2-digit'
				};
				break;
			case "date":
				options = {};
				break;
			case "time":
				options = {
					hour: '2-digit',
					minute: '2-digit'
				};
				functionName = "toLocaleTimeString";
				break;
			default:
				options = {}
		}
	} else {
		if (givenOptions) {
			options = givenOptions;
		}
	}
	const d = new Date(date);
	return d[functionName]("pt-BR", options); 
}

const firstLetters = (name, max=2) => {
	if (!name) {
		return name;
	}
	let parts = name.split(" ");
	let initials = "";
	parts.forEach((part, i) => {
		if (i < max) {
			initials = initials + part.charAt(0).toUpperCase();
		}
	});
	return initials;
} 

const intervals = [
	{ singular: 'ano', plural: 'anos', seconds: 31536000 },
	{ singular: 'mês', plural: 'meses', seconds: 2592000 },
	{ singular: 'dia', plural: 'dias', seconds: 86400 },
	{ singular: 'hora', plural: 'horas', seconds: 3600 },
	{ singular: 'minuto', plural: 'minutos', seconds: 60 },
	{ singular: 'segundo', plural: 'segundos', seconds: 0 }
];

function timeAgo(date) {
	const d = new Date(date);
	const seconds = Math.floor((Date.now() - d.getTime()) / 1000);
	const interval = intervals.find(i => i.seconds <= seconds);
	const count = Math.floor(seconds / interval.seconds);
	return `Há ${seconds < 60 ? 'poucos' : count} ${count !== 1 ? interval.plural : interval.singular}`;
}

export { formatBytes, formatDate, firstLetters, timeAgo };