import React from "react";
import Formlogin from "../components/formlogin";

export default class Login extends React.Component {

    render() {
        return <main>
            <h1>Planejamento Estratégico Contínuo</h1>
            <p>Insira seus dados para entrar.</p>
            <Formlogin action={this.props.action} />
        </main>
    }
}