import React from 'react';
import GlobalContext from '../globalContext.js';
import { validateHash, setNewPass } from "../utils/JWTAuth.js";
import { withRouter } from 'react-router-dom'
import Msg from './msg';

class Recover extends React.Component {

	constructor(props) {
		super(props);

		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);
		this.Content = this.Content.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.togglePassword = this.togglePassword.bind(this);
		this.PassRequirements = this.PassRequirements.bind(this);

		const path = this.props.location.pathname;
		this.hash = path.split("/")[2];

		this.state = {
			showPassword: false,
			data: {
				password: '', 
				password2: ''
			},
			isValid: false,
			msg: '',
			situation: '',
			userData: {},
			title: 'Recuperar o acesso'
		};
		this.props.setLoading(true);
	}
	
	static contextType = GlobalContext;

	async componentDidMount() {
		document.title = "Redefinir a senha";
	    const result = await validateHash(this.hash);
	    const success = result && result.success;
	    const msg = success ? '' : result.data;
		this.setState({
			isValid: success,
			situation: success ? 'form' : 'error',
			msgError: msg,
			msg: msg,
			userData: success ? result.data : {},
			title: success ? 'Recuperar o acesso' : 'Ops!'
		});
		this.props.setLoading(false);
	}

	togglePassword() {
		this.setState({showPassword: !this.state.showPassword});
	}
	
    
    PassRequirements () {
        const pass = this.state.data.password;
        let min = /^.{8,}$/.test(pass);
        let upp = /(?=.*[A-Z])/.test(pass);
        let low = /(?=.*[a-z])/.test(pass);
        let num = /(?=.*[0-9])/.test(pass);
		const passIsValid = (min && upp && low && num);
		const same = (this.state.data.password === this.state.data.password2);
		const incomplete = (passIsValid && !same);
		const valid = (passIsValid && same);
		const notValid = (!passIsValid && (pass.length === 0 || !same));
        const format = (text, valid) => (valid || pass.length === 0 ? text : <b style={{color:'var(--color-error)'}}>{text}</b>);
        return (<>

		{valid && (<span style={{color:'var(--color-success)'}} >
            Esta é uma boa senha!
        </span>)}

		{incomplete && (<span style={{color:'var(--color-error)'}} >
            Confirme a senha para ajudar a memorizá-la.
        </span>)}

		{notValid && (<span>
            A senha deve ter no {format("mínimo 8 caracteres", min)}, {format("letras maiúsculas", upp)}, {format("minúsculas", low)} e {format("números", num)} .
            {min}
        </span>)}

        </>);
    }

	showError(msgError) {
		this.setState({msgError: msgError});
	}
	
	hideError(msgError) {
		this.setState({msgError: ''});
	}
	
	handleInputChange(event) {
		const el = event.target;
		const name = el.name;
		const value = el.type === 'checkbox' ? (el.checked?1:0) : el.value;
		let data = this.state.data;
		data[name] = value;
		this.setState({ data: data });
		return el;
	}
	
	async handleSubmit(event) {
		event.preventDefault();
		this.props.setLoading(true);
		
		const form = event.target;
		const data = this.state.data;
		if (data.password !== data.password2) {
			return this.handleError(form['password2'], "A senha e a confirmação de senha devem ser iguais.") && false;
		}	
		const formData = new FormData(form);
		formData.append("hash", this.hash);
		formData.append("_METHOD", "PUT");
		for(var pair of formData.entries()) {
			console.log(pair[0]+ ', '+ pair[1]); 
		}		
		const result = await setNewPass(formData);
	    const success = result && result.success;
		let msgError = success ? '' : result && result.data;
		this.setState({
			msg: success ? 'Sua senha foi atualizada!' : msgError,
			situation: success ? 'success' : 'error',
			msgError: msgError,
			title: success ? 'Concluído!' : 'Não deu certo :('
		});
	    this.showError(msgError);
		this.props.setLoading(false);
	}

	Content() {
		const type = this.state.showPassword ? 'text' : 'password';
		if (this.state.situation === 'form') return (
			<form autoComplete="off" onSubmit={this.handleSubmit}>
			<fieldset>
				<legend>Olá, {this.state.userData.participant.nickname}</legend>
				<p>
					Defina a nova senha para recuperar o acesso à sua conta.
				</p>
		        <div className="fields">
					<div className='field input password'>
						<label htmlFor="password">Nova senha:</label>
						<input required name='password' type={type} 
							id='password'
							value={this.state.data.password} 
							placeholder='Digite uma senha forte'
							onChange={this.handleInputChange} 
							pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$"
							title="A senha deve ter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números." 
						/>
					</div>
					<div className='field input password'>
						<label htmlFor="password2">Confirmar senha:</label>
						<input required name='password2' type={type} 
							id='password2'
							value={this.state.data.password2} 
							placeholder='Digite a senha novamente'
							onChange={this.handleInputChange} 
							pattern={this.state.data.password}
							title="A confirmação da senha deve ser igual a senha informada." />
						<button type='button' className={'togglepass '+type} onClick={this.togglePassword}></button>
					</div>
					<small>
						{<this.PassRequirements />}
					</small>
				</div>
			</fieldset>
			<div className="buttons">
				<button type="submit" disabled={this.props.loading}>
					<span>Redefinir a senha</span>
				</button>
			</div>
		</form>
		);
		
		if (this.state.situation === 'error') return (
			<div>
				<h3>
					{this.state.msg}
				</h3>
				<button type='button' onClick={()=>this.context.openDialog('forget')}>Tentar novamente</button>
			</div>
		);
		
		if (this.state.situation === 'success') return (
			<div>
				<h3>
					{this.state.msg}
				</h3>
				<button type='button' onClick={()=>this.context.openDialog('login')}>
					<span>Entrar com a nova senha</span>
				</button>
			</div>
		);

		return null;
	}

	render() {

		return (
			<main className="recover">
				<h1>
					<span>{this.state.title}</span>
				</h1>
				<section>
				<this.Content />
				</section>
				<Msg text={this.state.msgError} onClose={this.hideError} />
			</main>
		);
	}
}

export default withRouter(Recover);