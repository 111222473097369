import React from 'react';
import { Link } from "react-router-dom";
import { getUsers } from "../utils/service.js";
import { isLogged, getUserData } from "../utils/JWTAuth.js";
import Msg from '../components/msg';
import GlobalContext from '../globalContext.js';
import Avatar from '../components/avatar.js';

class Users extends React.Component {
	constructor(props) {
		super(props);

		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);		
		this.itemUser = this.itemUser.bind(this);		

		this.userData = getUserData();
        this.props.setLoading(true); 
		
		this.state = {
			users: []
		};
	}
    
	static contextType = GlobalContext;
	
	async componentDidMount() {
		document.title = "Participantes";
		if (isLogged()) {
			let result = await getUsers();
			if (result && result.success) {
				result.data = result.data.map((o) => {
					o.self = false;
					if (o.id === this.userData.id) {
						o.nome += " (você)";
						o.self = true;
					}
					return o;
				});
				this.setState({users: result.data });
			}
			if (!result || !result.success) {
				this.showError("Provavelmente você não tem permissão para acessar a lista de usuários.");
				console.log(result);
			}
		} else {
			this.showError("Faça login para acessar a lista de usuários cadastrados.");
		}
	    this.props.setLoading(false); 
	}


	showError(msg) {
		this.setState({msg: msg});
	}
	

	hideError(msg) {
		this.setState({msg: ''});
	}


	itemUser({user}) {
		const self = this.userData.id === user.id;
		const link = self ? '/account' : "/participants/"+user.id;
		return (<li>
			<Link to={link}>
				<Avatar participant={user} />
				<span>{user.participant.nickname}</span>
			</Link>
		</li>);
	}
	

	render() {
		const users = this.state.users;
		return (
		<main className="participants">
			<header>
				<h2>{users.length} Participantes</h2>
			</header>
			<section>
				{users.length > 0 && (<ul>
					{users.map(o => <this.itemUser user={o} key={o.id} />)}
				</ul>)}
				{users.length === 0 && (
					<p>Nenhum participante apareceu por aqui.</p>
				)}
			</section>
			<Msg text={this.state.msg} onClose={this.hideError} />
		</main>
		);
	}
}

export default Users;