import React from 'react';

export default class Msg extends React.Component {
	constructor(props) {
	 	super(props);
		this.state = {
			text: this.props.text,
			type: this.props.type
		}
		this.close = this.close.bind(this);
	}
	componentDidMount() {
		clearInterval(this.timerID);
		this.timerID = setTimeout(() => {
			this.close();
		}, 6000);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	async playSound(type) {
		const playPromise = new Audio('./sounds/'+type+'.wav').play();
		if (playPromise !== undefined) {
			playPromise.then(()=>{}).catch((error)=>{});
		}
	}

	close () {
		this.props.onClose && this.props.onClose();
		this.props.setMsg && this.props.setMsg('');
	}

	render() {
		const text = this.props.text;
		const type = this.props.type || 'error';
		text && this.playSound(type);
		return text ? (
			<div className={'msg ' + type}>
				<span>
					{text}
				</span>
				<button className="close" type="button" onClick={this.close} title="Fechar"></button>
			</div>
		) : null;
	}
} 