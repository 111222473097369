import axios from 'axios';
import { SERVER_URL } from './config.js';

const login = async (data) => {
    const LOGIN_ENDPOINT = `${SERVER_URL}login`;

    try {

        let response = await axios.post(LOGIN_ENDPOINT, data);
        if(response.status === 200 && response.data && response.data.data.jwt && response.data.data.expire_at){
            let object = response.data.data;
            setUserData(object);
            localStorage.setItem("access_token", object["jwt"]);
            localStorage.setItem("expire_at", object["expire_at"]);
        }
    	return (response.data);
        
    } catch(e){
        return { success: false, data: e }
    }
}

const setUserData = (object) => {
    localStorage.setItem("access_data", JSON.stringify(object));
}

const register = async (data) => {
    const SIGNUP_ENDPOINT = `${SERVER_URL}register`;
    try {
        let response = await axios({
            method: 'post',
            responseType: 'json',
            url: SIGNUP_ENDPOINT,
            data: data
        });
     	return response.data;
    } catch(e){ console.error(e); }
}

const validateHash= async (hash) => {
    const SIGNUP_ENDPOINT = `${SERVER_URL}recover/${hash}`;
    try {
        let response = await axios({
            validateStatus: false,
            method: 'get',
            responseType: 'json',
            url: SIGNUP_ENDPOINT
        });
        return response.data;
    } catch(e){ 
        console.error(e); 
    }
}

const recover = async (data) => {
    const SIGNUP_ENDPOINT = `${SERVER_URL}recover`;
    try {
        let response = await axios({
            validateStatus: false,
            method: 'post',
            responseType: 'json',
            url: SIGNUP_ENDPOINT,
            data: data
        });
        return response.data;
    } catch(e){ 
        console.error(e); 
    }
}

const confirmEmail = async (hash) => {
    const SIGNUP_ENDPOINT = `${SERVER_URL}confirm`;
    try {
        let response = await axios({
            validateStatus: false,
            method: 'post',
            responseType: 'json',
            url: SIGNUP_ENDPOINT,
            data: { hash: hash }
        });
        return response.data;
    } catch(e){ 
        console.error(e); 
    }
}

const setNewPass = async (data) => {
    const SIGNUP_ENDPOINT = `${SERVER_URL}recover`;
    try {
        let response = await axios({
            validateStatus: false,
            method: 'post',
            responseType: 'json',
            url: SIGNUP_ENDPOINT,
            data: data
        });
        return response.data;
    } catch(e){ 
        console.error(e); 
    }
}

const isLogged = async (server = false) => {
    let access_token = localStorage.getItem("access_token");
    let expire_at = localStorage.getItem("expire_at");
    let now = new Date().getTime() / 1000;
    if (!access_token || expire_at <= now) {
        return false;
    }
    if (server) {
        const endpoint = `${SERVER_URL}login`;
        const token = "Bearer "+getToken();
        try {
            let response = await axios({
                url: endpoint,
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            });
            if(!response.status === 200 || !response.data) {
                return false;
            }            
            return response.data.valid;
        } 
        catch(e) { console.error(e); }
    }
    return true;
}

const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("access_data");
    localStorage.removeItem("expire_at");
    return !isLogged();
}

const getUserData = () => {
    let access_data = JSON.parse(localStorage.getItem("access_data"));    
    return isLogged() ? access_data : "";
}

const getToken = () => {
    let access_token = localStorage.getItem("access_token");    
    return isLogged() ? access_token : "";
}

export {
    login,
    register,
    logout,
    isLogged,
    getToken,
    setUserData,
    getUserData,
    recover,
    confirmEmail,
    validateHash,
    setNewPass
} 