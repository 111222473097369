import React from 'react';
import GlobalContext from '../globalContext.js';
import { confirmEmail } from "../utils/JWTAuth.js";
import { withRouter } from 'react-router-dom'
import Msg from './msg';


class Confirm extends React.Component {

	constructor(props) {
		super(props);

		this.hideError = this.hideError.bind(this);

		const path = this.props.location.pathname;
		this.hash = path.split("/")[2];
        this.state = {
			situation: '',
			user: {}
		};
        this.props.setLoading(true);

	}
	
	static contextType = GlobalContext;
	
	async componentDidMount() {
	    const result = await confirmEmail(this.hash);
	    const success = result && result.success;
		document.title = "Auati - " + (success ? "Cadastro ativado" : result.data);
		this.setState({
			situation: success ? 'success' : 'error',
			msg: success ? '' : result.data,
			user: success ? result.data[0] : {},
			msg_tmp: success ? '' : result.data,
		});
		this.props.setLoading(false);
	}

	showError(msg) {
		this.setState({msg_tmp: msg});
	}
	
	hideError(msg) {
		this.setState({msg_tmp: ''});
	}

	render() {

		return (
			<main className="confirm">
				<section>
				{this.state.situation === 'success' && (<>
                    <h1>Olá, {this.state.user.person.nickname}</h1>
                    <p>
                        Sua conta está ativa!
                    </p>
                    <p>
                        Não se esqueça de completar seu cadastro para 
                        desfrutar de todos os recursos do Auati!
                    </p>
					<button type='button' onClick={() => this.context.openDialog('login')}>Fazer login</button>
                </>)}
                
                {this.state.situation === 'error' && (<>
                    <div>
                        <h3>
                            Algo não saiu como o esperado.
                        </h3>
						<p>{this.state.msg}</p>
                    </div>
                </>)}
				</section>
				<Msg text={this.state.msg_tmp} onClose={this.hideError} />
			</main>
		);
	}
}

export default withRouter(Confirm);