import React from 'react';
import { withRouter } from "react-router-dom";
import FormSignup from '../components/formsignup.js';
import GlobalContext from '../globalContext.js';


class Newuser extends React.Component {

	static contextType = GlobalContext;

    componentDidMount() {
		document.title = "Criar uma conta - Auati";
		window.addEventListener('beforeunload', this.handleLeavePage);
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleLeavePage);
	}

	handleLeavePage(e) {
		const confirmationMessage = 'Verifique se os dados foram salvos.';
		e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
		return confirmationMessage;              // Gecko, WebKit, Chrome <34
	}

	handleError(el, msg = 'Erro desconhecido') {
		el.classList.add('error');
		return this.showError(msg);
	}

	render() {
		
		return (
			<main className="myaccount">
				{!this.context.newUser && (<>
				<h1>
					<span>Criar conta</span>
				</h1>
				<p>
					Faça parte dessa rede colaborativa de profissionais
					que desejam fazer permuta de serviços.
				</p>
				<p>
					Cadastre-se!
				</p>
				</>)}
				<FormSignup />
			</main>
		);
	}
}

export default withRouter(Newuser);