import React from "react";
import GlobalContext from '../globalContext.js';
import { InputImage, InputText, Textarea, Select, updateFields, pickFields, handleChange } from "../components/fields.js";
import Msg from "../components/msg.js";
import { addItem } from "../utils/service.js";
import { withRouter } from "react-router-dom";

/*
import Avatar from "../components/avatar.js";
import Participants from "../components/participants.js";
*/


class NewCommission extends React.Component {


	constructor(props) {

		super(props);
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showError = this.showError.bind(this);
		this.showSuccess = this.showSuccess.bind(this);
		this.hideError = this.hideError.bind(this);
        /*
        this.Card = this.Card.bind(this);
        this.Header = this.Header.bind(this);
		this.toggleForm = this.toggleForm.bind(this);

        
        commission: null,
        form: false,
        id: null
        */

       this.fieldNames = ['name', 'goal', 'symbol', 'id_user'];

       this.state = {
           fields: {},
       };
	}
	

	static contextType = GlobalContext;
	

	async componentDidMount() {
        document.title = "Planejamento Estratégico Contínuo - Nova Comissão";
		// let organization = this.context.data;
		let fields = updateFields('fieldsCommission_new', pickFields(null, this.fieldNames));
		this.setState({ fields });

		/*
		fields = updateFields('fieldsCommission_new', fields);
        /*
		const id = parseInt(document.location.pathname.split("/")[2]);
        if (!(organization && organization.commission)) {
            if (id) {
                organization = await this.context.setCommission(id);
            }
        }

        */
	}


	async handleSubmit(event) {
		event.preventDefault();
		this.context.setLoading(true);

		// const id = ''
		const form = event.target;
		const formData = new FormData(form);
        let result = '';
		try {			
            result = await addItem(formData, 'commission');
			if (!result.success) {
				return this.showError(result.data || 'Não foi possível salvar as informações.');
			}
		} catch (error) {
			console.error("Erro\n", error);
			return;
		}

        console.log(result.data);
		this.showSuccess('A comissão foi adicionada!');
		localStorage.removeItem('fieldsCommission_new');

		await this.context.loadPec();
		this.context.setLoading(false);
		this.props.history.push("/commission/" + result.data.id);
		// window.location.href = "/commission/" + result.data.id;		
	}

	showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	hideError(msg) {
		this.setState({msg: ''});
	}


	handleChange(event) {
		return handleChange(event, this);
	}

	back() {
		window.history.back();
	}


	render() {
		const commission = this.context.organization;
		if (!commission) {
			return "";
		}
		return (<>
			<main className="commission">
			<>
				<header>
					<h2>Nova</h2>
					<h1>Comissão</h1>
					<h4>Adicione uma nova comissão de acordo com o que foi estabelecido nos workshops.</h4>
				</header>
				{this.state.fields && (
				<form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
					<InputText 
						label='Nome'
						name="name"
						placeholder="Digite o nome da comissão"
						value={ this.state.fields.name }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Nome da comissão" } 
						pattern={ null }
					/>
					<Textarea
						label='Objetivo'
						name="goal"
						placeholder="Digite o objetivo desta comissão"
						value={ this.state.fields.goal }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Digite o objetivo que foi definido para esta comissão." } 
					/>
					<InputImage 
						label='Símbolo'
						name="symbol"
						placeholder="Imagem que represente a comissão"
						value={ this.state.fields.symbol }
						changeValue={ this.handleChange }
						accept={['svg', 'png', 'gif', 'jpg']}
						maxsize={5}
						entity="commission"
						instructions={ "Envie uma imagem no formato .svg, .png, .gif ou .jpg" } 
					/>
					<Select
						label='Responsável'
						name="id_user"
						placeholder="Selecione um responsável"
						value={ this.state.fields.id_user }
						changeValue={ this.handleChange }
						options={this.context.organization.participants.map(o => ({ name: o.participant.nickname + ' (' + o.participant.fullname + ')', value: o.id}))}
						instructions="Selecione o responsável por esta comissão entre os participantes." 
					/>
					<div className="buttons">
						<button type="submit">Salvar</button>
						<button type="button" onClick={this.back}>Voltar</button>
					</div>
				</form>)}
			</>
			{this.state.msg && <Msg text={this.state.msg} type={this.state.msgType} onClose={this.hideError} />}
			</main>
		</>
		);
	}
}

export default withRouter(NewCommission);