import axios from 'axios';
import { SERVER_URL } from './config.js';
import { getToken } from './JWTAuth.js';

const searchBy = async (object) => {
    let query = `?`;
    for (let property in object) {
        query += `${property}=${object[property]}`;
    }
    const endpoint = `${SERVER_URL}/search/?${query}`;
    try {
        
        let response = await axios.get(endpoint);
    	return (response.data);
        
    } 
    catch(e) { console.error(e); }
}

const searchByProtocol = async (protocol) => {
    const endpoint = `${SERVER_URL}/protocol/${protocol}`;
    const token = "Bearer "+getToken();
    try {
        let response = await axios({
            validateStatus: false,
            url: endpoint,
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { console.error(e); }
}

const getItems = async (path) => {
    const endpoint = SERVER_URL + path;
    const token = "Bearer "+getToken();
    try {
        let response = await axios({
            url: endpoint,
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { console.error(e); }
}

const getPEC = async param => { 
    // console.log(param)
    if (typeof param === 'object') {
        param = "?" + new URLSearchParams(param).toString(); 
    } else {
        param = param ? ("/" + param) : "";
    }
    // console.log(param)
    return getItems('pec' + param);
};

const getLikes = async id => getItems('likes' + (id ? '/' + id : ''));
const getMessages = async id => getItems('messages' + (id ? '/' + id : ''));
const getPerson = async id => getItems('person' + (id ? '/' + id : ''));
const getTickets = async id => getItems('tickets' + (id ? '/' + id : ''));
const getCalendar = async () => getItems('calendar');
const getOrganizations = async () => getItems('organizations');
const getSearch = async (text) => getItems('s='+text);


const getUsers = async id => getItems('users' + (id ? '/' + id : ''));
const getWPAds = async id => getItems('wpads' + (id ? '/' + id : ''));
const getOccurrences = async id => getItems('occurrences' + (id ? '/' + id : ''));
const getHistory = async id => getItems('historico' + (id ? '/' + id : ''));
    

const delItem = async (id, path) => {
    if (!id) {
        return { success: false, data: 'Informações insuficientes'};
    }
    path = path + "/" + id;
    const endpoint = SERVER_URL+path;
    const token = "Bearer "+getToken();
    let data = {
        id: id,
        _METHOD: "DELETE"
    }
    try {
        let response = await axios({
            validateStatus: false,
            url: endpoint,
            method: 'DELETE',
            data: data,
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { return e; }
}

const delAuatiRequest = (id) => delItem(id, "auatirequest");    

const setItem = async (data, id, path) => {
    if (!id) {
        return { success: false, data: 'Informações insuficientes'};
    }
    path = path + "/" + id;
    const endpoint = SERVER_URL+path;
    const token = "Bearer "+getToken();
	data.append("_METHOD", "PUT");
    try {
        let response = await axios({
            validateStatus: false,
            url: endpoint,
            method: 'POST',
            data: data,
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { return e; }
}

const setUser = (data, id) => setItem(data, id, "users");
const setLike = (data, id) => setItem(data, id, "likes");
const setAuati = (data, id) => setItem(data, id, "auati");

const addItem = async (data, path, auth = true, extra = {}) => {
    const endpoint = SERVER_URL+path;
    data.append("_METHOD", "POST");
    const config = {
        validateStatus: false,
        url: endpoint,
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': "multipart/form-data"
        }
    };
    for (const key in extra) {
        config[key] = extra[key];
    }
    if (auth) {
        const token = "Bearer "+getToken();
        config.headers.Authorization = token;
    }
    try {
        let response = await axios(config);
        return (response.data);
    } 
    catch(e) { console.error(e); }
}
const addAuatiRequest = (data) => addItem(data, "auatirequest");
const addMessage = (data) => addItem(data, "messages");

const addUser = (data) => addItem(data, "users");
const addOccurrence = (data, config) => addItem(data, "ocorrencias", true, config);

const addTicket = (data) => addItem(data, "tickets");


const patchItem = async (data, id, path, prop) => {
    if (!data || !id || !path || !prop) {
        return { success: false, data: 'Informações insuficientes'};
    }
    path = path + "/" + id + "/" + prop;
    const endpoint = SERVER_URL+path;
    const token = "Bearer "+getToken();
	data.append("_METHOD", "PATCH");
    try {
        let response = await axios({
            validateStatus: false,
            url: endpoint,
            method: 'POST',
            data: data,
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { return e; }
}
const patchAvatar = (data, id) => patchItem(data, id, "users", "avatar");
const patchResolved = (data, id) => patchItem(data, id, "tickets", "resolved");
const patchOrganization = (data, id) => patchItem(data, id, "organization", "last_access");

/* const addOccurrence_promise = (data, setResponse) => {
    axios({
        url: `${SERVER_URL}ocorrencias`,
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': "multipart/form-data"
        }
    }).then((response) => {
        setResponse(response.data);
    }).catch((error) => {
        setResponse("error");
    })
} */

/* const addOccurrence = (data, setResponse) => {
    axios({
        url: `${SERVER_URL}ocorrencias`,
        method: 'POST',
        data: data,
        headers: {
            'Content-Type': "multipart/form-data"
        }
    }).then((response) => {
        setResponse(response.data);
    }).catch((error) => {
        setResponse("error");
    })
} */

const getClassificacao = () => {

}

const getStatuses = async (setResponse) => {
    const endpoint = `${SERVER_URL}/statuses`;
    const token = "Bearer "+getToken();

    try {
        let response = await axios({
            url: endpoint,
            method: 'GET',
            headers: {
                'Authorization': token
            }
        });
        return (response.data);
    } 
    catch(e) { console.error(e); }
}

export {
    setItem,
    addItem,
    getItems,
    getPEC,
    getLikes,
    getMessages,
    setLike,
    delAuatiRequest,
    setAuati,
    getPerson,
    addMessage,
    patchAvatar,
    addTicket,
    getTickets,
    patchResolved,
    getCalendar,
    getOrganizations,
    patchOrganization,
    getSearch,
    
    searchBy,
    searchByProtocol,
    getClassificacao,
    getStatuses,
    getOccurrences,
    getWPAds,
    addOccurrence,
    addAuatiRequest,
    getUsers,
    addUser,
    setUser,
    getHistory
} 