import React from "react";
import { formatDate, timeAgo } from "../utils/convert.js";
import Avatar from "../components/avatar.js";

export default class Message extends React.Component {

    render() {
        const message = this.props.message;
        // const hasAttachment = !!(message.attachment);
        const classes = ['message'];
        if (message.ownertask) {
            classes.push('owner');
        }
        if (message.sameAuthor) {
            classes.push('same');
        }
        if (message.my) {
            classes.push('my');
        }
		return (<>
        <a name={'message' + message.id} href={'#message' + message.id}><span></span></a>
        <div className={classes.join(' ')}>
            <Avatar participant={message.author} />
            {message.type && (
            <span className="icon" title={message.type.name}>{message.type.icon}</span>
            )}
            <div className="balloon">
                <p>{message.text}</p>
                {message.attachment && (<div className="attachment">
                    <a href={message.attachment} target='_blank' rel="noopener noreferrer">{/[^/]+$/.exec(message.attachment)}</a>
                </div>)}
                <time dateTime={message.date_created} title={timeAgo(message.date_created)}>
                    {formatDate(message.date_created, {
                        day: "2-digit",
                        month: "2-digit",
                        hour: '2-digit',
                        minute: '2-digit'
                    })}
                </time>
            </div>
        </div>
        </>
		);
	}
    
}