import React from "react";
import Avatar from "../components/avatar.js";
import { handleChange, InputRadio, InputText, pickFields, Select, Textarea, updateFields } from "../components/fields.js";
import Participants from "../components/participants.js";
import GlobalContext from '../globalContext.js';
import { formatDate } from "../utils/convert.js";
import { getItems, setItem } from "../utils/service.js";
class Action extends React.Component {

	constructor(props) {
		super(props);
        this.Card = this.Card.bind(this);
        this.Header = this.Header.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
        this.addTask = this.addTask.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showError = this.showError.bind(this);
		this.showSuccess = this.showSuccess.bind(this);
		this.hideError = this.hideError.bind(this);
		this.updateObj = this.updateObj.bind(this);

        this.fieldNames = ['name', 'problem', 'solution', 'benefits', 'id_impact', 'id_user'];
        this.id = parseInt(document.location.pathname.split("/")[2]);
        this.identifier = "fieldsAction_" + this.id;

		this.state = {
            action: {},
            fields: pickFields(null, this.fieldNames),
			viabilityOptions: [],
			form: false,
			impacts: []
        };
	}
	
	static contextType = GlobalContext;

	async componentDidMount() {
		document.title = "Planejamento Estratégico Contínuo - Ações";
		let organization = this.context.data;
		
        if (!(organization && organization.action)) {
            if (this.id) {
				organization = await this.context.setAction(this.id);
            }
        }
		let fields = pickFields(organization.action, this.fieldNames);
		fields = updateFields(this.identifier, fields);
        let impacts = await getItems('impacts');
        let viabilities = await getItems('viabilities');

		const viabilityOptions = {};
		
		viabilities.data.forEach(o => {
			//fields[o.name] =  organization.action.viabilities.filter(v => v.id_viability === o.id)[0]['positive'];
			viabilityOptions[o.name] = [
				{ name: o.better, value: 1}, 
				{ name: o.worst, value: 0}
			];
		});
		
		// label = 'Viabilidade financeira';
		// label = 'Facilidade de execução';
		// label = 'Mão de obra para executar';
		
		
		this.setState({
			action: organization.action,
            id: this.id,
            fields,
			impacts: impacts.data,
			viabilityOptions
        });
		console.log('>>>>>', this.state);
	}

	Header({action}) {
		console.log(action);
		const total = action.total;
		const done = action.done;
		const percent = total && (Math.ceil(100 / total * done));
		const text = `${done} de ${total} tarefas concluídas`;
		return (<header>
            <h2>Ação</h2>
			<figure>
				<img src={'/images/status/'+action.status.icon} 
				alt="" title={action.status.name} />
			</figure>
            <h1>{action.name}</h1>
			<ul className="context">
				<li>
					<h5>Para alcançar o benefício de</h5>
					<p>
						{action.benefits}
					</p>
				</li>
				<li>
					<h5>Resolveremos o problema de</h5>
					<p>
						{action.problem}
					</p>
				</li>
				<li>
					<h5>Com a solução</h5>
					<p>
						{action.solution}
					</p>
				</li>
			</ul>
			<div className="viabilities">
            	{action.viabilities.map(o => <this.viability data={o} key={o.id} />)}
			</div>
			<div className="impact">
				<img src={'/images/impact/'+action.impact.icon} alt="" />
				<small>Maior impacto</small>
				<strong>{action.impact.text}</strong>
			</div>
			<Participants data={action} />
            <this.Status percent={percent} text={text} />
        </header>

		);
	}

	viability({data}) {
		const text = data.positive ? data.better : data.worst;
		return (
			<div className={"viability " + data.name + (data.good ? ' good' : '')}>
				<img src={'/images/viability/'+data.icon} alt='' />
                <span>{text}</span>
			</div>
		);
	}

    openTask(id) {
		document.location.href = '/task/' + id;
	}

	Card({task}) {
		console.log(task)
		return (
			<div className="card task" onClick={()=>this.openTask(task.id)}>
				<b>{task.name}</b>
                <img className="icon" src={'/images/status/'+task.status.icon} alt={task.status.name} />
				<time dateTime={task.end_time}>{formatDate(task.end_time)}</time>
				<Avatar participant={task.responsible} />
			</div>
		);
	}


	Status({percent, text}) {
		return (
		<div className="status">
			<p>{text}</p>
			<output>
				<span>{percent}</span>
				<small>%</small>
			</output>
			<progress value={percent} max='100'></progress>
		</div>
		);
	}

	async updateObj(name) {
		let obj = this.context.data;
        if (!(obj && obj[name])) {
			if (this.id) {
				let functionName = 'set' + name[0].toUpperCase() + name.substring(1);
                return await this.context[functionName](this.id);
            }
        }
	}

	async handleSubmit(event) {
		event.preventDefault();
		this.context.setLoading(true);

		const id = this.id;
		const form = event.target;
		const formData = new FormData(form);
		
		try {			
			const result = await setItem(formData, id, 'action');
			if (!result.success) {
				return this.showError(result.data || 'Não foi possível salvar as informações.');
			}
		} catch (error) {
			console.error("Erro\n", error);
			return;
		}

		localStorage.removeItem(this.identifier);
		
		this.setState({
			form: false,
			showConfirm: true
		});

		await this.context.loadPec();
		this.context.setLoading(false);
		// return this.showSuccess('Seus dados foram salvos');
	}


	showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	hideError(msg) {
		this.setState({msg: ''});
	}


	handleChange(event) {
		return handleChange(event, this);
	}


	toggleForm() {
		this.setState({ form: !this.state.form })
	}


	addTask() {
		window.location.assign("action/" + this.id + "/task");
	}


	render() {
		const action = this.context.organization && this.context.organization.action;
		if (!action) {
			return "";
		}
		const tasksDone = action.tasks.filter(o => o.done === true);
		const tasksNotDone = action.tasks.filter(o => o.done !== true);

		console.log("|||||||||||", 	this.state.viabilityOptions.cost);
		return (
			<main className="action">

				{!action && (<header>
					<h2>Ação</h2>
					<h1>Ação não encontrada</h1>
					<p className="empty">
						A ação que você está tentando acessar provavelmente não existe ou você não possui acesso a ela.
					</p>
					<p className="empty">
						<a href="/">Voltar ao inicio</a>
					</p>
				</header>)}

				{(action && !this.state.form) && (<>
					<this.Header action={action} />
	
					{tasksNotDone.length > 0 && (<label>Tarefas não concluídas</label>)}
					{tasksNotDone.map(o => <this.Card task={o} key={o.id} />)}

					{tasksDone.length > 0 && (<label>Tarefas concluídas</label>)}
					{tasksDone.map(o => <this.Card task={o} key={o.id} />)}

					<footer className="extras">
						<button onClick={this.toggleForm} type="button" className="edit"><span>Editar esta ação</span></button>
						<button onClick={this.addTask} type="button" className="add"><span>Adicionar tarefa</span></button>
					</footer>
				</>)}

				{(action && this.state.form) && (<>
					
					<header>
						<h2>Editar</h2>
						<h1>Ação</h1>
						<h4>Altere as informações de acordo com o que foi estabelecido nos workshops.</h4>
					</header>

					{this.state.fields && (
					<form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
						<InputText 
							label='Nome'
							name="name"
							placeholder="Digite o nome da ação"
							value={ this.state.fields.name }
							changeValue={ this.handleChange }
							required={true}
							instructions={ "Nome curto e fácil de entender para esta ação" } 
							pattern={ null }
						/>
						<Textarea
							label='Problema'
							name="problem"
							placeholder="Problema relacionado com esta ação"
							value={ this.state.fields.problem }
							changeValue={ this.handleChange }
							required={true}
							instructions={ "Digite o problema definido pelo grupo que esta ação reolve." } 
						/>
						<Textarea
							label='Solução'
							name="solution"
							placeholder="Solução proposta com esta ação"
							value={ this.state.fields.solution }
							changeValue={ this.handleChange }
							required={true}
							instructions={ "Informe de que maneira a ação vai solucinar o problema." } 
						/>
						<Textarea
							label='Benefícios'
							name="benefits"
							placeholder="Benefícios alcançáveis por esta ação"
							value={ this.state.fields.benefits }
							changeValue={ this.handleChange }
							required={true}
							instructions={ "Informe quais são os benefícios almejados depois que a solução for desenvolvida." } 
						/>
						<InputRadio
							label='Impacto'
							name="id_impact"
							placeholder="Selecione o maior impacto"
							value={ this.state.fields.id_impact }
							changeValue={ this.handleChange }
							options={this.state.impacts.map(o => ({ name: o.text, value: o.id}))}
							instructions="Informe qual o maior impacto gerado por esta ação." 
						/>
						{this.state.viabilityOptions.cost && (<InputRadio
							label='Viabilidade financeira'
							name='cost'
							placeholder={this.state.viabilityOptions.cost}
							value={ this.state.fields.cost || 0 }
							changeValue={ this.handleChange }
							options={ this.state.viabilityOptions.cost }
							instructions="Informe se é caro ou barato, de acordo com o sentimento da equipe." 
						/>)}
						{this.state.viabilityOptions.cost && (<InputRadio
							label='Facilidade de execução'
							name='ease'
							placeholder={this.state.viabilityOptions.ease}
							value={ this.state.fields.ease || 0 }
							changeValue={ this.handleChange }
							options={ this.state.viabilityOptions.ease }
							instructions="Informe se é fácil ou difícil, de acordo com o sentimento da equipe." 
						/>)}
						{this.state.viabilityOptions.have && (<InputRadio
							label='Mão de obra para executar'
							name='have'
							placeholder={this.state.viabilityOptions.have}
							value={ this.state.fields.have || 0 }
							changeValue={ this.handleChange }
							options={ this.state.viabilityOptions.have }
							instructions="Informe se temos ou precisaremos contratar a mão de obra capaz de executar a ação." 
						/>)}
						
						<Select
							label='Responsável'
							name="id_user"
							placeholder="Selecione um responsável"
							value={ this.state.fields.id_user }
							changeValue={ this.handleChange }
							options={this.context.organization.participants.map(o => ({ name: o.participant.nickname + ' (' + o.participant.fullname + ')', value: o.id}))}
							instructions="Selecione o responsável por esta ação entre os participantes." 
						/>
						<div className="buttons">
							<button type="submit">Salvar</button>
							<button type="button" onClick={this.toggleForm}>Voltar</button>
						</div>
					</form>
					)}
				</>)}
			</main>
		);
	}
}

export default Action;