import React from 'react';
import { Link } from 'react-router-dom';

class Error404 extends React.Component {

    componentDidMount() {
		document.title = "AUATI - Página não encontrada";
    }
    
    render() {
        return (
            <main className='notfound'>
                <header>
                    <h2>
                        <span>Ops!</span>
                    </h2>
                    <figure>
                        <img src="/images/sorvete-caido.png" alt="" />
                    </figure>
                    <h1>Página não encontrada.</h1>
                    <p className='empty'>Confira o endereço digitado.</p>
                    <p className='empty'><span>Quer voltar para o início?</span>
                        <Link to='/'> Clique aqui.</Link>
                    </p>
                </header>
            </main>
        )
    }
}

export default Error404;