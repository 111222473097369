import React, { Component, Suspense } from 'react';
import './App.css';
import { 
    isLogged, 
    getUserData, 
    logout 
} from "./utils/JWTAuth.js";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import {GlobalProvider} from './globalContext';

import Msg from "./components/msg.js";
import Dialog from './components/dialog';
import Top from "./components/top";
import Formlogin from './components/formlogin';
import Formforget from './components/formforget';
import FormSignup from './components/formsignup';
import Recover from "./components/recover";
import Confirm from "./components/confirm";
import User from "./pages/user";
import Error404 from "./pages/error404";
// import Footer from "./components/footer";

import Organization from "./pages/organization";
// import NewAd from "./pages/newad";
// import Person from "./pages/person";
// import AdDone from "./pages/addone";
// import Ads from "./pages/ads";
// import Associating from "./pages/associating";
// import Myaccount from "./pages/myaccount";
// import Users from "./pages/users";
// import User from "./pages/user";
import Newuser from "./pages/newuser";
// import History from "./pages/history";
import Commission from './pages/commission';
import Action from './pages/action';
import Task from './pages/task';
import { getPEC } from './utils/service';
import Login from './pages/login';
import Myaccount from './pages/myaccount';
import Users from './pages/users';
import PecSteps from './pages/pecsteps';
import Help from './pages/help';
import Calendar from './pages/calendar';
import { SERVER_URL } from './utils/config';
import Conversations from './pages/conversations';
import NewCommission from './pages/newcommission';
import Newaction from './pages/newaction';
import Newtask from './pages/newtask';

class App extends Component {

    constructor(props) {
        super (props);
        
        this.setLoading = this.setLoading.bind(this);
        this.setUploadProgress = this.setUploadProgress.bind(this);
        this.setAccepted = this.setAccepted.bind(this);
        this.setLoginStatus = this.setLoginStatus.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.doLogout = this.doLogout.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.doForget = this.doForget.bind(this);
        this.doSignup = this.doSignup.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.getEmail = this.getEmail.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.setMsg = this.setMsg.bind(this);
        this.setNewUser = this.setNewUser.bind(this);
        this.PrivateRoute = this.PrivateRoute.bind(this);
        this.loadPec = this.loadPec.bind(this);
        this.setTask = this.setTask.bind(this);
        this.setAction = this.setAction.bind(this);
        this.setCommission = this.setCommission.bind(this);
        
        this.state = {
            logged: false,
            userData: getUserData(),
            email: '',
            dialogData: null,
            loading: false,
            accepted: false, //?
            redirect: false,
            redirectPath: '',
            search: '',
            uploadProgress: 0,
            msg: {
                text: '',
                type: ''
            },
            newUser: null,
            organization: null
        };
    }

    async componentDidMount() {
        const logged = await isLogged(true);
        this.setState({logged});
        const pec = await this.loadPec();
        if (pec) {
            const id = pec.id;
            this.insertCSS(id);
        }

        this.idInterval = setInterval(this.loadPec, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.idInterval);
    }

    insertCSS(id) {
        var css_variables = document.getElementById('css_variables');
        var head = document.getElementsByTagName('HEAD')[0];
        if (css_variables) {
            head.removeChild(css_variables);
        }
        if (!document.getElementById('css_variables')) {
            var link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = SERVER_URL.replace("/api", "") + 'css_variables.php?id=' + id;
            link.id = 'css_variables';
            head.appendChild(link);
        }
    }

    async loadPec() {

        if (!this.state.logged) {
            return;
        }
        console.log(this.state.logged);
        const loadData = await getPEC();

        if (!loadData || !loadData.success) {
            if (this.state.logged) {
                this.setMsg('Não foi possível acessar os dados');
            }
            return;
        }
        
        let organization = loadData.data;
        const page = document.location.pathname.split("/")[1];
        const id = parseInt(document.location.pathname.split("/")[2]);
        
        if (page === 'commission' && id) {
            organization = await this.setCommission(id, organization);
        }

        if (page === 'action' && id) {
            organization = await this.setAction(id, organization);
        }

        if (page === 'task' && id) {
            organization = await this.setTask(id, organization);
        }

        this.setState({ organization });
        // console.log(organization.name);
        return organization;
    }
    
    async setCommission(id, organization) {
        if (organization) {
            if (organization.commission) {
                return organization.commission;
            }
        } else {
            const loadData = await getPEC();
            organization = loadData.data;
        }
        
        const commission = organization.commissions.filter(o => o.id === id);
        if (commission.length > 0) {
            organization.commission = commission[0];
        }
        this.setState({ organization });
        return organization;
    }

    async setAction(id, organization) {
        if (organization) {
            if (organization.action) {
                return organization.action;
            }
        } else {
            const loadData = await getPEC();
            organization = loadData.data;
        }
        const commission = organization.commissions.filter(o => o.actions.filter(a => a.id === id).length > 0);
        console.log("ACTION", id, commission);
        if (commission.length > 0) {
            organization.commission = commission[0];
            organization.action = organization.commission.actions.filter(a => a.id === id)[0];
        }
        this.setState({ organization });
        return organization;
    }

    async setTask(id, organization) {
        if (organization) {
            if (organization.task) {
                return organization.task;
            }
        } else {
            const loadData = await getPEC();
            organization = loadData.data;
        }
        // console.log("--->", id, organization.commissions.filter(c => c.id === 1));
        const commission = organization.commissions.filter(o => o.actions.filter(a => a.tasks.filter(t => t.id === id).length > 0 ).length > 0);
        if (commission.length > 0) {
            organization.commission = commission[0];
            organization.action = organization.commission && organization.commission.actions.filter(a => a.tasks.filter(t => t.id === id))[0];
            organization.task = organization.action && organization.action.tasks.filter(t => t.id === id)[0];
        }
        this.setState({ organization });
        return organization;
    }

    PrivateRoute (path) {
        const logged = this.state.logged;
        return logged ? path.children : <Login action={this.doLogin} />;
    }
    
    render() {
        this.bodyClasses(this.state.logged, 'logged');
        return (
        <GlobalProvider value={{
            loginDialog: this.setDataDialog('login'),
            setMsg: this.setMsg,
            openDialog: this.openDialog,
            closeDialog: this.closeDialog,
            setEmail: this.setEmail,
            newUser: this.state.newUser,
            setNewUser: this.setNewUser,
            data: this.state.organization,
            organization: this.state.organization,
            loadPec: this.loadPec,
            setTask: this.setTask,
            setAction: this.setAction,
            setCommission: this.setCommission,
            logged: this.state.logged,
            setLoginStatus: this.setLoginStatus,
            loading: this.state.loading,
            setLoading: this.setLoading,
            insertCSS: this.insertCSS,
            search: this.state.search,
            setSearch: this.setSearch
            }}>
        <Suspense>
        <React.Fragment>
            <Router basename={process.env.PUBLIC_URL}>
                {/* <Top 
                Router={Router}
                setLoginStatus={this.setLoginStatus} 
                openLoginDialog={this.openLoginDialog}
                setSearch={this.setSearch}
            /> */}

                <Top 
                doLogout={this.doLogout}
                userData={this.state.userData}
                logged={this.state.logged} 
                />
                
                {this.renderRedirect()}

                <Switch>

                    <this.PrivateRoute exact path="/">
                        <Organization />
                    </this.PrivateRoute>
                   
                    <Route exact path="/commission">
                        <NewCommission 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        /> 
                    </Route>

                    <Route exact path="/commission/:id">
                        <Commission />
                    </Route>

                    <Route exact path="/commission/:id/action">
                        <Newaction />
                    </Route>

                    <Route exact path="/action/:id/task">
                        <Newtask />
                    </Route>

                    <Route exact path="/action/:id">
                        <Action />
                    </Route>

                    <Route path="/task/:id">
                        <Task />
                    </Route>

                    <Route path="/calendar/">
                        <Calendar />
                    </Route>
                   
                    <Route path="/conversations/">
                        <Conversations />
                    </Route>

                    <Route path="/recover/:hash">
                        <Recover 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        openLoginDialog={this.openLoginDialog}
                        closeLoginDialog={this.closeLoginDialog}
                        />
                    </Route>

                    <Route path="/confirm/:hash">
                        <Confirm 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        />
                    </Route>

                    <Route path="/signup">
                        <Newuser 
                        logged={this.state.logged} 
                        setLoginStatus={this.setLoginStatus} 
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                        userData={this.state.userData}
                        />
                    </Route>
                   
                    <Route path="/account">
                        <Myaccount 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        /> 
                    </Route>
                   
                    <Route exact path="/participants">
                        <Users 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        /> 
                    </Route>
                   
                    <Route exact path="/participants/:id">
                        <User 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        /> 
                    </Route>
                   
                    <Route exact path="/pec">
                        <PecSteps 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        /> 
                    </Route>
                   
                    <Route path="/help">
                        <Help 
                        setLoading={this.setLoading} 
                        loading={this.state.loading}
                        /> 
                    </Route>
                   
                    <Route>
                        <Error404 />
                    </Route>
                </Switch>

				{/* <Footer /> */}


                {this.state.dialogData &&
                    <Dialog 
                    data={this.state.dialogData} 
                    onClose={this.closeDialog} 
                    /> 
                }

                {this.state.shareDialog && 
                    <Dialog 
                    data={this.shareDialogData} 
                    onClose={this.closeShareDialog} 
                    /> 
                }
            </Router>
            {this.state.loading && (
                <div className="loading">
                {this.state.uploadProgress > 0 && this.state.uploadProgress < 100 && (
                    <div className="upload-progress">
                        <progress value={this.state.uploadProgress} max="100"></progress>
                        <p className="porcent">
                            <span className="raise" style={{height: (100 - this.state.uploadProgress)+'%'}}></span>
                            {this.state.uploadProgress}%
                        </p>
                    </div>
                )}
                {(this.state.uploadProgress === 0 || this.state.uploadProgress === 100) && (
                    <p className="waiting">Aguarde enquanto estamos processando...</p>
                    )}
            </div>
            )}
            {this.state.msg.text && <Msg text={this.state.msg.text} type={this.state.msg.type} setMsg={this.setMsg} />}
        </React.Fragment>
        </Suspense>
        </GlobalProvider>
    );
    }
    
    doLogout() {

        this.setState({ 
            logged: !logout(),
            redirect: true,
            redirectPath: "/",
            userData: ''
        });
    }

    doLogin(result) {
        let success = result && result.success;
        let path = success ? '/' : '/';
        console.log(success);
        if (success) {
            this.loadPec();
            this.setState({ 
                logged: success,
                redirect: true,
                redirectPath: path,
                userData: success ? getUserData() : ''
            });
        }
    }

    setNewUser(data) {
        this.setState({newUser: data})
    }
    
    doForget(result) {
        let success = result && result.success;
        this.setState({ loginDialog: success })
    }

    doSignup(result) {
        let success = result && result.success;
        this.setState({ loginDialog: success })
    }

    setLoading(isLoading) {
        this.setState({ loading: isLoading });
    }
    
    setUploadProgress(value) {
        this.setState({ uploadProgress: value });
    }

    setEmail(email) {
        this.setState({ email: email });
    }

    getEmail() {
        return this.state.email;
    }

    setLoginStatus(value) {
        this.setState({ 
            redirect: true,
        });
    }
    
    setMsg(text, type) {
        console.log(text, type);
        this.setState({
            msg: {
                text: text,
                type: type || 'error'
            }
        });
    }

    setDataDialog(type) {
        switch (type) {
            case 'login':
                return {
                    name: 'login',
                    title: 'Acesse a sua conta',
                    modal: false,
                    content: <Formlogin 
                        action={this.doLogin} 
                        close={this.closeDialog} 
                        email={this.state.email}
                        setEmail={this.setEmail}
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                    />
                }
            
            case 'forget':
                return {
                    name: 'forget',
                    title: 'Recuperar senha',
                    modal: false,
                    content: <Formforget 
                        action={this.doForget} 
                        close={this.closeDialog} 
                        getEmail={this.getEmail}
                        setEmail={this.setEmail}
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                    />
                }
                
            case 'signup':
                return {
                    name: 'signup',
                    title: 'Criar conta',
                    modal: false,
                    content: <FormSignup 
                        action={this.doSignup} 
                        close={this.closeDialog} 
                        getEmail={this.getEmail}
                        setEmail={this.setEmail}
                        loading={this.state.loading}
                        setLoading={this.setLoading} 
                    />
                }
            default: return null;
        }
    }

    openDialog(data) {
        if (typeof data === 'string') {
            data = this.setDataDialog(data);
        }
        if (data) {
            this.setState({
                dialogData: data,
            });
        }
    }
    
    closeDialog() {
        this.setState({dialogData: null})
    }

    setSearch(value) {
        this.setState({search: value});
    }
    
    setAccepted(value) {
        this.setState({ accepted: value });
    }


    // renderRedirectx() {
    //     let path = this.state.logged ? '/' : '/';
    //     if (this.state.redirect) {
    //         return <Redirect to={path} />
    //     }
    // }

    renderRedirect() {
        let path = this.state.redirectPath;
        if (this.state.redirect) {
            return <Redirect to={path} />
        }
    }

    bodyClasses(toApply, className) {
        if (toApply) {
            document.body.classList.add(className);
        } else {
            document.body.classList.remove(className);
        }
    }

}

export default App;