import React from "react";
import { firstLetters } from "../utils/convert";

export default class Avatar extends React.Component {
    constructor(props) {
        super(props);
        this.action = this.action.bind(this);
    }

    action() {
        if (this.props.action) {
            this.props.action(); 
        }
    }

    render() {
        const p = this.props.participant;
        if (!p) {
            return <figure className="avatar"><img src='/images/participant/question.svg' alt='' title='Não foi atribuído.'/></figure>;
        }
        const src = p.participant && p.participant.avatar;
        const classes = 'avatar' + (!!this.props.action ? ' pointer' : '');
        const fl = (!!p && !src) ? firstLetters(p.username) : false;
        return (
            <figure className={classes} onClick={this.action}>
            {(!p || p.length === 0) && (
                <img src='/images/participant/question.svg' alt='' title='Não foi atribuído.'/>
            )}
            {src && (
                <img src={'/images/participant/' + src} alt='' title={p.participant.nickname}/>
            )}
            {fl && (
                <span title={p.username}>{fl}</span>
            )}
            {this.props.edit && (
                <div className='edit'></div>
            )}
            </figure>
        );
    }
}