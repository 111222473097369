import React from 'react';
import { withRouter } from 'react-router-dom';
import { isLogged, getUserData, setUserData } from '../utils/JWTAuth.js';
import Msg from '../components/msg.js';
import { setUser } from '../utils/service.js';
import GlobalContext from '../globalContext.js';
import Avatar from '../components/avatar.js';
import FormPhoto from "../components/formphoto.js";


class Myaccount extends React.Component {
	constructor (props) {
		super(props);
		this.timerId = null;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);
		this.handleError = this.handleError.bind(this);
		this.PassRequirements = this.PassRequirements.bind(this);
		this.togglePassword = this.togglePassword.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.changePhoto = this.changePhoto.bind(this);
		this.applyPhoto = this.applyPhoto.bind(this);
		this.handleLeavePage = this.handleLeavePage.bind(this);
		this.cancelEdit = this.cancelEdit.bind(this);
		this.goEditMode = this.goEditMode.bind(this);
		this.dataModified = this.dataModified.bind(this);
		this.resetData = this.resetData.bind(this);
		this.seePhoto = this.seePhoto.bind(this);
		this.cancelSeePhoto = this.cancelSeePhoto.bind(this);
		this.magnifyPhoto = this.magnifyPhoto.bind(this);
		
		let userdata = getUserData();
		userdata = { ...userdata, ...userdata.participant};
		this.state = {
			data: userdata,
			msg: '',
			msgType: 'error',
			changePassword: false,
			mode: 'view', // ( view | edit | photo )
			magnifiedPhoto: false 
		};

		if (!isLogged()) {
			this.props.history.push('/');
		}
	    this.props.setLoading(true); 
	}

    static contextType = GlobalContext;

	async componentDidMount() {

		document.title = 'Meus dados de usuário';
		window.addEventListener('beforeunload', this.handleLeavePage);
		// load user data
		if (!isLogged()) {
			this.props.history.push('/');
		}				
		this.props.setLoading(false); 
	}
	
	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleLeavePage);
	}

	dataModified() {
		const originalData = JSON.stringify({ ...getUserData(), ...getUserData().participant});
		const currentData = JSON.stringify(this.state.data);
		return originalData !== currentData;
	}

	resetData() {
		this.setState({ data: { ...getUserData(), ...getUserData().participant}})
	}

	handleLeavePage(e) {
		if (this.dataModified()) {
			const confirmationMessage = 'Verifique se os dados foram salvos.';
			e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
			return confirmationMessage;              // Gecko, WebKit, Chrome <34
		}
	}

	handleError(el, msg = 'Erro desconhecido') {
		el.classList.add('error');
		return this.showError(msg);
	}

	async handleSubmit(event) {
		console.log(event);
		this.props.setLoading(true);

		event.preventDefault();
		const form = event.target;
		const data = this.state.data;
		
		const formData = new FormData(form);
		formData.append('id', data.id);
		const result = await setUser(formData, data.id);
		if (!result.success) {
			return this.showError(result.data || 'Não foi possível salvar seus dados.');
		}
		setUserData(result.data);

		this.setState({
			changePassword: false,
			mode: 'view'
		});
		this.resetData();
		this.props.setLoading(false);
		return this.showSuccess('Seus dados foram salvos');
	}

	handleInputChange2(event) {
		const data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({
			data
		})
	}
	handleInputChange(event) {
		const el = event.target;
		const name = el.name;
		const value = el.type === 'checkbox' ? (el.checked?1:0) : el.value;
		let data = this.state.data;
		data[name] = value;
		if (!data.hasOwnProperty(name)) {
			this.setState({ data });
			console.log(name, value, data);
			return;
		}
		this.setState({ data });
		localStorage['setUserData'] = JSON.stringify(data);
		return el;
	}

	handleChangePassword(event) {
		const el = event.target;
		const value = el.checked ? 1 : 0;
		this.setState({ changePassword: value });
	}
	
	togglePassword() {
		this.setState({showPassword: !this.state.showPassword});
	}
	
    PassRequirements () {
        const pass = this.state.data.password;
        let min = /^.{8,}$/.test(pass);
        let upp = /(?=.*[A-Z])/.test(pass);
        let low = /(?=.*[a-z])/.test(pass);
        let num = /(?=.*[0-9])/.test(pass);
		const passIsValid = (min && upp && low && num);
		const same = (this.state.data.password === this.state.data.password2);
		const incomplete = (passIsValid && !same);
		const valid = (passIsValid && same);
		const notValid = (!passIsValid && (pass.length === 0 || !same));
        const format = (text, valid) => (valid || pass.length === 0 ? text : <b style={{color:'var(--color-error)'}}>{text}</b>);
        return (<>

		{valid && (<span style={{color:'var(--color-success)'}} >
            Esta é uma boa senha!
        </span>)}

		{incomplete && (<span style={{color:'var(--color-error)'}} >
            Confirme a senha para ajudar a memorizá-la.
        </span>)}

		{notValid && (<span>
            A senha deve ter no {format('mínimo 8 caracteres', min)}, {format('letras maiúsculas', upp)}, {format('minúsculas', low)} e {format('números', num)} .
            {min}
        </span>)}

        </>);
    }

	showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	
	hideError(msg) {
		this.setState({msg: ''});
	}

	async changePhoto() {
		this.context.openDialog({
			name: 'photo',
			title: 'Selecionar foto',
			modal: false,
			content: <FormPhoto
				action={this.applyPhoto} 
				close={this.context.closeDialog} 
				loading={this.context.loading}
				setLoading={this.context.setLoading} 
				participant={this.state.data}
			/>
		});
	};

	applyPhoto(participant) {
		const userdata = getUserData();
		userdata.participant = participant;
		setUserData(userdata);
		this.setState({
			data: { ...userdata, ...userdata.participant },
			mode: 'edit'
		});
	}

	goEditMode() {
		this.setState({mode: 'edit'});
	}
	
	cancelEdit() {
		if (this.dataModified()) {
			if (window.confirm("Sair sem salvar as aterações?")) {
				this.resetData();			
				this.setState({mode: 'view'});
			}
		} else {
			this.setState({mode: 'view'});
		}
	}

	seePhoto() {
		let src = this.state.data.participant && this.state.data.participant.avatar;
		if (src) {
			this.setState({mode: 'photo'});
		}
	}

	cancelSeePhoto() {
		this.setState({mode: 'view'});
	}

	magnifyPhoto() {
		let m = this.state.magnifiedPhoto;
		if (!m) {
			this.setState({magnifiedPhoto: true})
		}
		if (m) {
			this.setState({
				magnifiedPhoto: false,
				mode: 'view'
			})
		}
	}
	
	render() {
		const type = this.state.showPassword ? 'text' : 'password';
		return (
			<main className='myaccount'>
				<header>
					<h2>Meus dados</h2>
				</header>
				{this.state.mode === 'photo' && (<>
					<div className={'seephoto' + (this.state.magnifiedPhoto ? ' magnified' : '')}>
						<img alt='' onClick={this.magnifyPhoto} src={'/images/participant/' + this.state.data.participant.avatar} />
					</div>
				</>)}
				{this.state.mode === 'view' && (<>
				<Avatar participant={this.state.data} action={this.seePhoto} />
				<h1>
					<span>{this.state.data.participant.nickname}</span>
					<small>{this.state.data.fullname}</small>
				</h1>
				<h4>
					<label>E-mail</label>
					<span>{this.state.data.email}</span>
				</h4>
				<h4>
					<label>Telefone</label>
					<span>{this.state.data.tel}</span>
				</h4>
				<h4>
					<label>Cep</label>
					<span>{this.state.data.postalcode}</span>
				</h4>
				<div className='buttons'>
					<button type='button' onClick={this.goEditMode}>Alterar meus dados</button>
				</div>
				</>)}

				{this.state.mode === 'edit' && (<form autoComplete='off' onSubmit={this.handleSubmit}>
					<fieldset>
						<legend>
							Mantenha seus dados atualizados.
						</legend>
		                <div className='fields'>
							<label>Foto</label>
							<Avatar participant={this.state.data} edit={true} action={this.changePhoto} />
							<div className='field inputtext fullname'>
								<label htmlFor='fullname'>Seu nome completo</label>
								<input required name='fullname' id='fullname' type='text' value={this.state.data.fullname} onChange={this.handleInputChange} />
							</div>
							<div className='field inputtext nickname'>
								<label htmlFor='nickname'>Nome curto</label>
								<input required name='nickname' id='nickname' type='text' value={this.state.data.nickname} onChange={this.handleInputChange} />
                            	<p>Escreva o seu apelido ou como gosta de ser chamado.</p>
							</div>
							<div className='field inputemail email'>
								<label htmlFor='email'>Seu e-mail</label>
								<input required autoComplete='off' name='email' id='email' type='email' value={this.state.data.email} onChange={this.handleInputChange} />
								<p>Enviaremos uma confirmação para seu e-mail.</p>
							</div>
							<div className='field inputtext tel'>
								<label htmlFor='tel'>Celular</label>
								<input required id='tel' name='tel' type='text' value={this.state.data.tel} onChange={this.handleInputChange} />
								<p>No formato (00) 00000-0000</p>
							</div>
							<div className='field inputtext postalcode'>
								<label htmlFor='postalcode'>CEP</label>
								<input id='postalcode' name='postalcode' type='text' value={this.state.data.postalcode} onChange={this.handleInputChange} />
								<p>Utilize o CEP do local onde mora</p>
							</div>
							<div className='field inputcheckbox changePassword'>
								<label htmlFor='changePassword'>Quero alterar minha senha</label>
								<input
									name='changePassword'
									id='changePassword'
									type='checkbox'
									checked={this.state.changePassword}
									onChange={this.handleChangePassword} 
								/>
							</div>

						{!!this.state.changePassword && (
						<>	
							<div className='field input password'>
								<label htmlFor='password'>Nova senha:</label>
								<input required name='password' type={type} 
									id='password'
									value={this.state.data.password} 
									placeholder='Digite uma senha forte'
									onChange={this.handleInputChange} 
									pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$'
									title='A senha deve ter no mínimo 8 caracteres, letras maiúsculas, minúsculas e números.' 
								/>
							</div>
							<div className='field input password'>
								<label htmlFor='password2'>Confirmar senha:</label>
								<input required name='password2' type={type} 
									id='password2'
									value={this.state.data.password2} 
									placeholder='Digite a senha novamente'
									onChange={this.handleInputChange} 
									pattern={this.state.data.password}
									title='A confirmação da senha deve ser igual a senha informada.' />
								<button type='button' className={'togglepass '+type} onClick={this.togglePassword}></button>
							</div>
							<small>
								{<this.PassRequirements />}
							</small>
						</>)}
						</div>

					</fieldset>
					
					<footer>
						<div className='buttons'>
							<button type='submit' disabled={this.props.loading}>
								<span>Salvar meus dados</span>
							</button>
							<button type="button" onClick={this.cancelEdit}>Cancelar</button>
						</div>
					</footer>
					
				</form>)}
				{this.state.msg && <Msg text={this.state.msg} type={this.state.msgType} onClose={this.hideError} />}
			</main>
		);
	}
}

export default withRouter(Myaccount);