import React from "react";
import Avatar from "../components/avatar.js";
import { InputImage, InputText, Textarea, Select, updateFields, pickFields, handleChange } from "../components/fields.js";
import Msg from "../components/msg.js";
import Participants from "../components/participants.js";
import GlobalContext from '../globalContext.js';
import { setItem } from "../utils/service.js";


class Commission extends React.Component {


	constructor(props) {

		super(props);
		
        this.Card = this.Card.bind(this);
        this.Header = this.Header.bind(this);
		this.toggleForm = this.toggleForm.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showError = this.showError.bind(this);
		this.showSuccess = this.showSuccess.bind(this);
		this.hideError = this.hideError.bind(this);
		this.updateObj = this.updateObj.bind(this);
		this.addAction = this.addAction.bind(this);

		this.fieldNames = ['name', 'goal', 'symbol', 'id_user'];

		this.state = {
			commission: null,
			form: false,
			fields: {},
			id: null
		};		
	}
	

	static contextType = GlobalContext;
	

	async updateObj(name) {
		const id = parseInt(document.location.pathname.split("/")[2]);
		let obj = this.context.data;
        if (!(obj && obj[name])) {
			if (id) {
				let functionName = 'set' + name[0].toUpperCase() + name.substring(1);
                return await this.context[functionName](id);
            }
        }
	}
	
	
	async componentDidMount() {
		document.title = "Planejamento Estratégico Contínuo - Comissão";
		let organization = await this.updateObj('commission');
		console.log("ORGANIZATION", organization);
		if (!organization.commission) {
			return true;
		}
		const id = organization.commission.id;
		let fields = pickFields(organization.commission, this.fieldNames);
		fields = updateFields('fieldsCommission_' + id, fields);
        this.setState({
			commission: organization.commission,
			id,
			fields
        });
	}
	
	
	async componentDidUpdate() {
		let organization = this.context.organization;
		let fields = pickFields(organization.commission, this.fieldNames);
		if (!organization.commission) {
			return true;
		}
		const id = organization.commission.id;
		if (this.state.id && (id !== this.state.id)) { //mudou de commissão
			console.log('Update', fields);
			fields = updateFields('fieldsCommission_' + id, fields);
			this.setState({ fields, id });
		}
	}


	Header({commission}) {
		const total = commission.total;
		const done = commission.done;
		const percent = (done === 0 || total === 0) ? 0 : Math.ceil(100 / total * done);
		const text = `${done} de ${total} tarefas concluídas`;
		return (<header>
            <h2>Comissão</h2>
			<figure className="symbol">
				<img src={'/images/commission/'+commission.symbol} alt="" />
			</figure>
            <h1>{commission.name}</h1>
            <p>
                {commission.goal}
            </p>    
            <Participants data={commission} />
            <this.Status percent={percent} text={text} />
        </header>
		);
	}
	
    
	toggleForm() {
		this.setState({ form: !this.state.form })
	}


	async handleSubmit(event) {
		event.preventDefault();
		this.context.setLoading(true);

		const id = this.state.id;
		const form = event.target;
		const formData = new FormData(form);
		
		try {			
			const result = await setItem(formData, id, 'commission');
			if (!result.success) {
				return this.showError(result.data || 'Não foi possível salvar as informações.');
			}
		} catch (error) {
			console.error("Erro\n", error);
			return;
		}

		localStorage.removeItem('fieldsCommission_' + id);
		
		this.setState({
			form: false,
			showConfirm: true
		});

		await this.context.loadPec();
		this.context.setLoading(false);
		// return this.showSuccess('Seus dados foram salvos');
	}


	showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	hideError(msg) {
		this.setState({msg: ''});
	}


	handleChange(event) {
		return handleChange(event, this);
	}


	openAction(id) {
		document.location.href = '/action/' + id;
	}


	Card({action}) {
		const percent = (action.done === 0 || action.total === 0) ? 0 : Math.ceil(100 / action.total * action.done);
		return (
			<div className="card action" onClick={()=>this.openAction(action.id)}>
				<this.Status percent={percent} text={action.name} />
                <img className="icon" src={'/images/status/'+action.status.icon} alt="" />
                <p>
                    {action.done} de {action.total} concluídas.
                </p>
                <Avatar participant={action.responsible} />
			</div>
		);
	}


	Status({percent, text}) {
		return (
		<div className="status">
			<h4>{text}</h4>
			<output>
				<span>{percent}</span>
				<small>%</small>
			</output>
			<progress value={percent} max='100'></progress>
		</div>
		);
	}


	addAction() {
		window.location.assign("commission/" + this.state.id + "/action");
	}

	render() {
		const commission = this.context.organization && this.context.organization.commission;
		return (
			<main className="commission">

				{!commission && (<header>
					<h2>Comissão</h2>
					<h1>Comissão não encontrada</h1>
					<p className="empty">
						A comissão que você está tentando acessar não existe.
					</p>
					<p className="empty">
						<a href="/">Voltar ao inicio</a>
					</p>
				</header>)}

				{(commission && !this.state.form) && (
					<>
					<this.Header commission={commission} />
					<label>Ações</label>
					{commission.actions.map(o => <this.Card action={o} key={o.id} />)}


					<footer className="extras">
						<button onClick={this.toggleForm} type="button" className="edit"><span>Editar esta comissão</span></button>
						<button onClick={this.addAction} type="button" className="add"><span>Adicionar Ação</span></button>
					</footer>
					
					</>
				)}

				{(commission && this.state.form) && (<>
					<header>
						<h2>Editar</h2>
						<h1>Comissão</h1>
						<h4>Altere as informações de acordo com o que foi estabelecido nos workshops.</h4>
					</header>
					{this.state.fields && (
					<form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
						<InputText 
							label='Nome'
							name="name"
							placeholder="Digite o nome da comissão"
							value={ this.state.fields.name }
							changeValue={ this.handleChange }
							required={true}
							instructions={ "Nome da comissão" } 
							pattern={ null }
						/>
						<Textarea
							label='Objetivo'
							name="goal"
							placeholder="Digite o objetivo desta comissão"
							value={ this.state.fields.goal }
							changeValue={ this.handleChange }
							required={true}
							instructions={ "Digite o objetivo que foi definido para esta comissão." } 
						/>
						<InputImage 
							label='Símbolo'
							name="symbol"
							placeholder="Imagem que represente a comissão"
							value={ this.state.fields.symbol }
							changeValue={ this.handleChange }
							accept={['svg', 'png', 'gif', 'jpg']}
							maxsize={5}
							entity="commission"
							instructions={ "Envie uma imagem no formato .svg, .png, .gif ou .jpg" } 
						/>
						<Select
							label='Responsável'
							name="id_user"
							placeholder="Selecione um responsável"
							value={ this.state.fields.id_user }
							changeValue={ this.handleChange }
							options={this.context.organization.participants.map(o => ({ name: o.participant.nickname + ' (' + o.participant.fullname + ')', value: o.id}))}
							instructions="Selecione o responsável por esta comissão entre os participantes." 
						/>
						<div className="buttons">
							<button type="submit">Salvar</button>
							<button type="button" onClick={this.toggleForm}>Voltar</button>
						</div>
					</form>)}
				</>)}

				{this.state.msg && <Msg text={this.state.msg} type={this.state.msgType} onClose={this.hideError} />}

			</main>
		);
	}
}

export default Commission;