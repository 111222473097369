import React from "react";
import GlobalContext from '../globalContext.js';
import { InputText, Textarea, Select, updateFields, pickFields, handleChange, InputRadio, InputDate } from "../components/fields.js";
import Msg from "../components/msg.js";
import { addItem, getItems } from "../utils/service.js";
import { withRouter } from "react-router-dom";

/*
import Avatar from "../components/avatar.js";
import Participants from "../components/participants.js";
*/


class NewTask extends React.Component {


	constructor(props) {

		super(props);
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showError = this.showError.bind(this);
		this.showSuccess = this.showSuccess.bind(this);
		this.hideError = this.hideError.bind(this);
        
        this.fieldNames = ['name', 'details', 'id_status', 'cost', 'start_time', 'end_time', 'done', 'id_user'];
        this.identifier = 'fieldsTask_new';
        this.id_action = parseInt(document.location.pathname.split("/")[2]);
        this.state = {
            fields: pickFields(null, this.fieldNames),
            impacts: []
        };
	}
	

	static contextType = GlobalContext;
	

	async componentDidMount() {
        document.title = "Planejamento Estratégico Contínuo - Nova Comissão";
		// let organization = this.context.data;
		let fields = updateFields(this.identifier, pickFields(null, this.fieldNames));
        let impacts = await getItems('impacts');
		this.setState({ fields, impacts });

		/*
		fields = updateFields(this.identifier, fields);
        /*
		const id = parseInt(document.location.pathname.split("/")[2]);
        if (!(organization && organization.commission)) {
            if (id) {
                organization = await this.context.setCommission(id);
            }
        }

        */
	}


	async handleSubmit(event) {
		event.preventDefault();
		this.context.setLoading(true);

		const id = this.id_action;

        if (!id) {
            return this.showError('Não foi possível enviar os dados.');
        }
		const form = event.target;
		const formData = new FormData(form);
        formData.append('id_action', id);
        let result = '';
		try {			
            result = await addItem(formData, 'tasks');
			if (!result.success) {
				return this.showError(result.data || 'Não foi possível salvar as informações.');
			}
		} catch (error) {
			console.error("Erro\n", error);
			return;
		}

        // console.log(result.data);
		this.showSuccess('A ação foi adicionada!');
		localStorage.removeItem(this.identifier);

		await this.context.loadPec();
		this.context.setLoading(false);
		this.props.history.push("/task/" + result.data.id);
		// window.location.href = "/commission/" + result.data.id;		
	}

	showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	hideError(msg) {
		this.setState({msg: ''});
	}


	handleChange(event) {
		return handleChange(event, this);
	}

	back() {
		window.history.back();
	}


	render() {
		const commission = this.context.organization;
		if (!commission) {
			return "";
		}
        // console.log(this.state.fields)
		return (<>
			<main className="commission">
			<>
				<header>
					<h2>Nova</h2>
					<h1>Tarefa</h1>
					<h4>Adicione uma nova tarefa de acordo com o que foi estabelecido nos workshops.</h4>
				</header>
				{this.state.fields && (
				<form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
					<InputText 
						label='Nome'
						name="name"
						placeholder="Digite o nome da ação"
						value={ this.state.fields.name }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Nome curto e fácil de entender para esta ação" } 
						pattern={ null }
					/>
					<Textarea
						label='Detalhes'
						name="details"
						placeholder="Detalhamento da tarefa"
						value={ this.state.fields.details }
						changeValue={ this.handleChange }
						required={false}
						instructions={ "Escreva as etapas necessárias para executar a tarefa." } 
					/>
					<InputDate
						label='Início'
						name="start_time"
						placeholder="Data de início"
						value={ this.state.fields.start_time }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Defina a data prevista para início de execução desta tarefa." } 
						pattern={ null }
					/>
					<InputDate
						label='Conclusão'
						name="end_time"
						placeholder="Data de término"
						value={ this.state.fields.end_time }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Defina a data de previsão de término desta tarefa." } 
						pattern={ null }
					/>
					<InputRadio
						label='Concluído'
						name="done"
						value={ this.state.fields.done }
						changeValue={ this.handleChange }
						options={[{ name: 'Sim', value: '1' },{ name: 'Não', value: '0' }]}
						instructions="Informe se esta tarefa já foi concluída." 
					/>
					<Select
						label='Responsável'
						name="id_user"
						placeholder="Selecione um responsável"
						value={ this.state.fields.id_user }
						changeValue={ this.handleChange }
						options={this.context.organization.participants.map(o => ({ name: o.participant.nickname + ' (' + o.participant.fullname + ')', value: o.id}))}
						instructions="Selecione o responsável por esta ação entre os participantes." 
					/>
					<div className="buttons">
						<button type="submit">Salvar</button>
						<button type="button" onClick={this.back}>Voltar</button>
					</div>
				</form>)}
			</>
			{this.state.msg && <Msg text={this.state.msg} type={this.state.msgType} onClose={this.hideError} />}
			</main>
		</>
		);
	}
}

export default withRouter(NewTask);