import React from "react";
import GlobalContext from '../globalContext.js';
import { InputText, Textarea, Select, updateFields, pickFields, handleChange, InputRadio } from "../components/fields.js";
import Msg from "../components/msg.js";
import { addItem, getItems } from "../utils/service.js";
import { withRouter } from "react-router-dom";

/*
import Avatar from "../components/avatar.js";
import Participants from "../components/participants.js";
*/


class NewAction extends React.Component {


	constructor(props) {

		super(props);
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showError = this.showError.bind(this);
		this.showSuccess = this.showSuccess.bind(this);
		this.hideError = this.hideError.bind(this);
        
        this.fieldNames = ['name', 'problem', 'solution', 'benefits', 'id_impact', 'id_user'];
        this.identifier = 'fieldsCommission_new';
        this.id_commission = parseInt(document.location.pathname.split("/")[2]);
        this.state = {
            fields: pickFields(null, this.fieldNames),
            impacts: []
        };
	}
	

	static contextType = GlobalContext;
	

	async componentDidMount() {
        document.title = "Planejamento Estratégico Contínuo - Nova Comissão";
		// let organization = this.context.data;
		let fields = updateFields(this.identifier, pickFields(null, this.fieldNames));
        let impacts = await getItems('impacts');
		this.setState({ fields, impacts });

		/*
		fields = updateFields(this.identifier, fields);
        /*
		const id = parseInt(document.location.pathname.split("/")[2]);
        if (!(organization && organization.commission)) {
            if (id) {
                organization = await this.context.setCommission(id);
            }
        }

        */
	}


	async handleSubmit(event) {
		event.preventDefault();
		this.context.setLoading(true);

		const id = this.id_commission;

        if (!id) {
            return this.showError('Não foi possível enviar os dados.');
        }
		const form = event.target;
		const formData = new FormData(form);
        formData.append('id_commission', id);
        let result = '';
		try {			
            result = await addItem(formData, 'action');
			if (!result.success) {
				return this.showError(result.data || 'Não foi possível salvar as informações.');
			}
		} catch (error) {
			console.error("Erro\n", error);
			return;
		}

        // console.log(result.data);
		this.showSuccess('A ação foi adicionada!');
		localStorage.removeItem(this.identifier);

		await this.context.loadPec();
		this.context.setLoading(false);
		this.props.history.push("/action/" + result.data.id);
		// window.location.href = "/commission/" + result.data.id;		
	}

	showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	hideError(msg) {
		this.setState({msg: ''});
	}


	handleChange(event) {
		return handleChange(event, this);
	}

	back() {
		window.history.back();
	}


	render() {
		const commission = this.context.organization;
		if (!commission) {
			return "";
		}
        // console.log(this.state.fields)
		return (<>
			<main className="commission">
			<>
				<header>
					<h2>Nova</h2>
					<h1>Ação</h1>
					<h4>Adicione uma nova ação de acordo com o que foi estabelecido nos workshops.</h4>
				</header>
				{this.state.fields && (
				<form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
					<InputText 
						label='Nome'
						name="name"
						placeholder="Digite o nome da ação"
						value={ this.state.fields.name }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Nome curto e fácil de entender para esta ação" } 
						pattern={ null }
					/>
					<Textarea
						label='Problema'
						name="problem"
						placeholder="Problema relacionado com esta ação"
						value={ this.state.fields.problem }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Digite o problema definido pelo grupo que esta ação reolve." } 
					/>
					<Textarea
						label='Solução'
						name="solution"
						placeholder="Solução proposta com esta ação"
						value={ this.state.fields.solution }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Informe de que maneira a ação vai solucinar o problema." } 
					/>
					<Textarea
						label='Benefícios'
						name="benefits"
						placeholder="Benefícios alcançáveis por esta ação"
						value={ this.state.fields.benefits }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Informe quais são os benefícios almejados depois que a solução for desenvolvida." } 
					/>
					<InputRadio
						label='Impacto'
						name="id_impact"
						placeholder="Selecione o maior impacto"
						value={ this.state.fields.id_impact }
						changeValue={ this.handleChange }
						options={this.context.organization.participants.map(o => ({ name: o.participant.nickname + ' (' + o.participant.fullname + ')', value: o.id}))}
						instructions="Informe qual o maior impacto gerado por esta ação." 
					/>
					<Select
						label='Responsável'
						name="id_user"
						placeholder="Selecione um responsável"
						value={ this.state.fields.id_user }
						changeValue={ this.handleChange }
						options={this.context.organization.participants.map(o => ({ name: o.participant.nickname + ' (' + o.participant.fullname + ')', value: o.id}))}
						instructions="Selecione o responsável por esta ação entre os participantes." 
					/>
					<div className="buttons">
						<button type="submit">Salvar</button>
						<button type="button" onClick={this.back}>Voltar</button>
					</div>
				</form>)}
			</>
			{this.state.msg && <Msg text={this.state.msg} type={this.state.msgType} onClose={this.hideError} />}
			</main>
		</>
		);
	}
}

export default withRouter(NewAction);