import React from "react";
import Avatar from "../components/avatar.js";
import { patchAvatar } from "../utils/service.js";

export default class FormPhoto extends React.Component {
    constructor(props) {
        super(props);

		this.openCamera = this.openCamera.bind(this);
		this.closeCamera = this.closeCamera.bind(this);
		this.capture = this.capture.bind(this);
		this.cancelCamera = this.cancelCamera.bind(this);
		this.savePhoto = this.savePhoto.bind(this);
		this.removePhoto = this.removePhoto.bind(this);
		this.sendFile = this.sendFile.bind(this);

		this.video = React.createRef();
		this.canvas = React.createRef();

        this.stream = null;

        this.state = {
            mode: 'form',
            loading: false,
            captured: false,
            file: null
        }
    }
    
    async openCamera() {
        this.setState({loading: true, captured: false});
        await this.setState({mode: "camera"}, async () => {
            this.stream = await navigator.mediaDevices.getUserMedia({ 
                video: { width: 720, height: 720 }, 
                audio: false 
            });
            this.video.current.srcObject = this.stream;
            this.setState({loading: false});
        });
    }

    closeCamera() {
        this.stream.getTracks().forEach(function(track) {
            track.stop();
        });
    }
    
    cancelCamera() {
        this.closeCamera();
        this.setState({mode: "form", captured: false});
    }

    capture() {
        const id = this.props.participant.id_user;
        this.setState({captured: true}, () => {
            const video = this.video.current;
            const canvas = this.canvas.current;
            const canvasContext = canvas.getContext('2d');
            canvasContext.drawImage(video, 0, 0, canvas.width, canvas.height);
            this.image_captured = canvas.toDataURL('image/jpeg');

            let file = null;
            this.canvas.current.toBlob((blob) => {
                file = new File([blob], 'avatar'+id+'.jpg', { type: 'image/jpeg' });
                this.setState({file});
            }, 'image/jpeg');
            this.closeCamera();
        });
    }

    async savePhoto() {
        const id = this.props.participant.id_user;
        this.props.setLoading(true);
		const formData = new FormData();
        formData.append("avatar", this.state.file);
        const result = await patchAvatar(formData, id);
        if (result.success) {
           this.props.action(result.data);
           this.props.close();
        } else {
            alert("Ocorreram erros ao alterar a foto.");
        }
    }

    async removePhoto() {
        const id = this.props.participant.id_user;
        this.props.setLoading(true);
		const formData = new FormData();
        formData.append("avatar", 'none');
        const result = await patchAvatar(formData, id);
        if (result.success) {
           this.props.action(result.data);
           this.props.close();
        } else {
            alert("Ocorreram erros ao remover a foto.");
        }
    }

    async sendFile(e) {
        this.setState({ loading: true });
        const id = this.props.participant.id_user;
        this.props.setLoading(true);

        const input = e.target;
        if(input.files.length === 0) {
            this.setState({ loading: false });
            return;
		}
		let file = input.files[0];
		let allowed_mime_types = [ 'image/jpeg', 'image/png' ];
		let allowed_size_mb = 5;
        
		if(allowed_mime_types.indexOf(file.type) === -1) {
            alert('Escolha uma imagem no formato JPEG ou PNG.');
            this.setState({ loading: false });
			return;
		}
		if(file.size > allowed_size_mb*1024*1024) {
            alert('Arquivo muito grande. \nEscolha um arquivo de até '+allowed_size_mb+'MB.');
            this.setState({ loading: false });
			return;
		}
		let formData = new FormData();
		formData.append('avatar', input.files[0]);
        const result = await patchAvatar(formData, id);
        if (result.success) {
            this.props.action(result.data);
            this.props.close();
        } else {
            alert("Ocorreram erros ao enviar o arquivo da foto.");
        }
        this.setState({ loading: false });
    }

    render() {
        const havePhoto = this.props.participant;
        return (<form>

        {this.state.mode === 'form' && (
            <Avatar participant={this.props.participant} />
        )}
        {this.state.mode === 'camera' && (
        <figure className='photo-container'> 
            <video style={this.state.captured ? {display: 'none'} : {}} ref={this.video} width="1024" height="1024" autoPlay></video>
            <canvas style={this.state.captured ? {} : {display: 'none'}} ref={this.canvas} width="1024" height="1024" />
        </figure>
        )}
        {this.state.loading && (
        <figure className='photo-container'> 
            <div className='loading'></div>
        </figure>
        )}

        <p>Selecione uma opção:</p>
        {this.state.mode === 'form' && (
        <div className='fields'>
            <button type="button" onClick={this.openCamera}>Usar a câmera</button>
            <label className="button" htmlFor="inputFile" >Enviar um arquivo</label>
            <input type="file" id="inputFile" accept="image/jpeg, image/png" onChange={this.sendFile} style={{display: 'none'}} />
            {havePhoto && (<button type="button" onClick={this.removePhoto}>Remover foto atual</button>)}
        </div>
        )}
        {this.state.mode === 'camera' && (
        <div className='fields'>
        {this.state.captured && (<>
            <button type="button" onClick={this.savePhoto}>Usar esta foto</button>
            <button type="button" onClick={this.openCamera}>Tirar outra</button>
        </>)}
        {!this.state.captured && (<>
            <button type="button" onClick={this.capture}>Capturar foto</button>
        </>)}
            <button type="button" onClick={this.cancelCamera}>Cancelar</button>
        </div>
        )}
        </form>);
    }
}