import React from "react";
import { InputImage, InputText, Textarea } from "../components/fields.js";
import Msg from "../components/msg.js";
import GlobalContext from '../globalContext.js';
import { setItem } from "../utils/service.js";

class Organization extends React.Component {

	constructor(props) {
		super(props);
		this.toggleForm = this.toggleForm.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setFields = this.setFields.bind(this);
		this.addCommission = this.addCommission.bind(this);

		this.fieldNames = ['name', 'purpose', 'logo'];
		
		//this.lastEdition = localStorage['fieldsOrganization'] ? JSON.parse(localStorage['fieldsOrganization']) : null;
		
		this.state = {
			form: false,
			fields: {},
			id: null,
			showConfirm: true
		};
	}
	
	static contextType = GlobalContext;
	
	async componentDidMount() {
		document.title = "Planejamento Estratégico Contínuo";
		//this.setState({ fields })
		let organization = this.context.data;
        if (!(organization)) {
			organization = await this.context.loadPec();
        }
		const id = organization.id;

		// get field's properties from organization object
		let fields = this.setFields(organization);

		console.log('Mount', fields);

		fields = this.UpdateFields(id, fields);
	
        this.setState({ fields, id });
	}
	
	componentDidUpdate() {
		let organization = this.context.data;
		const id = organization && organization.id;
		let fields = this.setFields(organization);
		if (this.state.id && (id !== this.state.id)) { //mudou de organização
			console.log('Update', fields);
			fields = this.UpdateFields(id, fields);
			this.setState({ fields, id });
		// } else {
		// 	if (this.state.showConfirm) {
		// 		if (JSON.stringify(fields) !== localStorage['fieldsOrganization' + id]) {
		// 			if (window.confirm('Os dados foram alterados enquanto você editava.\nDeseja abandonar a edição e carregar os dados novos?')) {
		// 				localStorage['fieldsOrganization' + id] = JSON.stringify(fields);
		// 				this.setState({ fields });
		// 			}
		// 			this.setState({ showConfirm: false })
		// 		}
		// 	}
		}
	}

	UpdateFields(id, fields) {
		const stringFields = JSON.stringify(fields);
		if (!localStorage['fieldsOrganization' + id]) {
			localStorage['fieldsOrganization' + id] = stringFields;
		} else {
			if (stringFields !== localStorage['fieldsOrganization' + id]) {
				if(window.confirm('Há alterações não salvas. \nDeseja retomar a edição de onde parou?')) {
					fields = JSON.parse(localStorage['fieldsOrganization' + id]);
				} else {
					localStorage['fieldsOrganization' + id] = stringFields;
				}
			}
		}
		return fields;
	}

	setFields(organization) {
		if (!organization) { return null; }
		return ((organization) => {
			const r = {};
			for(let i in this.fieldNames) {
				if (organization.hasOwnProperty(this.fieldNames[i])) {
					r[this.fieldNames[i]] = organization[this.fieldNames[i]];
				}
			}
			return r;
		})(organization);
	}

	Logo({logo}) {
		return (
			<figure>
				<img src={"/images/organization/" + logo} alt="" />
			</figure>
		);
	}

	openCommission(id) {
		document.location.href = '/commission/' + id;
	}

	Card(o) {
		const percent = (o.done === 0 || o.total === 0) ? 0 : Math.ceil(100 / o.total * o.done);
		return (
			<div className="card commission" key={o.id} onClick={()=>this.openCommission(o.id)}>
				<figure>
					<img src={'/images/commission/'+o.symbol} alt="" />
				</figure>
				<this.Status percent={percent} text={o.name} />
			</div>
		);
	}

	toggleForm() {
		this.setState({ form: !this.state.form })
	}

	Status({percent, text}) {
		if (isNaN(percent)) {
			percent = 0;
		}
		return (
		<div className="status">
			<p>{text}</p>
			<output>
				<span>{percent}</span>
				<small>%</small>
			</output>
			<progress value={percent} max='100'></progress>
		</div>
		);
	}

	showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	
	hideError(msg) {
		this.setState({msg: ''});
	}

	handleChange(event) {
		let name = null;
		let value = null;
		if (event.target) {
			const el = event.target;
			name = el.name;
			value = el.type === 'checkbox' ? (el.checked?1:0) : el.value;
		} else {
			name = event.name;
			value = event.value;
		}
		let fields = this.state.fields;
		fields[name] = value;
		if (!fields.hasOwnProperty(name)) {
			console.log(name, value, fields);
			return;
		}
		this.setState({ fields });
		localStorage['fieldsOrganization' + this.state.id] = JSON.stringify(fields);
		// return el;
	}

	async handleSubmit(event) {
		event.preventDefault();
		console.log(event);
		this.context.setLoading(true);

		const id = this.state.id;
		const form = event.target;
		
		const formData = new FormData(form);
		try {			
			const result = await setItem(formData, id, 'organizations');
			if (!result.success) {
				return this.showError(result.data || 'Não foi possível salvar as informações.');
			}
		} catch (error) {
			console.error("Erro\n", error);
			return;
		}

		localStorage.removeItem('fieldsOrganization' + id);
		
		this.setState({
			form: false,
			showConfirm: true
		});

		this.context.setLoading(false);
		// return this.showSuccess('Seus dados foram salvos');
	}


	addCommission() {
		window.location.assign("/commission");
	}


	render() {
		if (!this.context.data || !this.context.data.commissions) {
			return <main><p>Nenhum dado foi localizado.</p></main>;
		}
		const data = this.context.data;
		const commissions = data.commissions;
		const total = data.total;
		const done = data.done;
		const percent = Math.ceil(100 / total * done);
		const text = `${done} de ${total} tarefas concluídas`;
		return (<>
			<main className="organization">
				
				{!this.state.form && (<>
				<header>
					<this.Logo logo={data.logo} />
					<h1 title="Propósito">{data.purpose}</h1>
					<this.Status percent={percent} text={text} />
				</header>
				<label>Comissões</label>
				{commissions.map(o => this.Card(o))}
				<footer className="extras">
					<button onClick={this.toggleForm} type="button" className="edit"><span>Editar esta organização</span></button>
					<button onClick={this.addCommission} type="button" className="add"><span>Adicionar comissão</span></button>
				</footer>
				</>)}
				
				{this.state.form && (<>
				<header>
					<h2>Editar</h2>
					<h1>Organização</h1>
					<h4>Altere as informações de acordo com o que foi estabelecido nos workshops.</h4>
				</header>
				{this.state.fields && (
				<form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
					<InputText 
						label='Nome'
						name="name"
						placeholder="Digite o nome da organização"
						value={ this.state.fields.name }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Nome da organização (sua empresa ou instituição)" } 
						pattern={ null }
					/>
					<Textarea
						label='Propósito'
						name="purpose"
						placeholder="Digite o propósito da organização"
						value={ this.state.fields.purpose }
						changeValue={ this.handleChange }
						required={true}
						instructions={ "Digite o propósito definido para a organização." } 
					/>
					<InputImage 
						label='Logotipo'
						name="logo"
						placeholder="Digite o logotipo da organização"
						value={ this.state.fields.logo }
						changeValue={ this.handleChange }
						accept={['svg', 'png', 'gif', 'jpg']}
						maxsize={5}
						entity="organization"
						instructions={ "Envie uma imagem no formato .svg, .png, .gif ou .jpg" } 
					/>
					<div className="buttons">
						<button type="submit">Salvar</button>
						<button type="button" onClick={this.toggleForm}>Voltar</button>
					</div>
				</form>)}
				</>)}
				{this.state.msg && <Msg text={this.state.msg} type={this.state.msgType} onClose={this.hideError} />}
			</main>
		</>
		);
	}
}

export default Organization;