import React from 'react';
import Msg from './msg';
import GlobalContext from '../globalContext.js';
import { login } from "../utils/JWTAuth.js";

class Formlogin extends React.Component {

	constructor(props) {
		super (props);
	
		this.login = this.login.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);
		this.forgetPassword = this.forgetPassword.bind(this);
		this.togglePassword = this.togglePassword.bind(this);
		this.signup = this.signup.bind(this);
		this.getTokenAndSendData = this.getTokenAndSendData.bind(this);

		this.state = {
			msg: '',
			email: '',
			password: '',
			showPassword: false
		};
	}

	static contextType = GlobalContext;

	togglePassword() {
		this.setState({showPassword: !this.state.showPassword});
	}

	handleInputChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({
			[name]: value
		});
		if (name === 'email' && this.context.setEmail) {
			this.context.setEmail(value);
		}
	}

	forgetPassword() {
		this.context.setEmail(this.state.email);
		this.context.openDialog('forget');
	}

	signup() {
		const path = window.location.pathname.split("/")[1];
		this.context.setEmail(this.state.email);
		if (path !== 'signup') {
			this.context.openDialog('signup');
		} else {
			this.props.close();
		}
	}

	showError(msg) {
		this.setState({msg: msg});
	}
	
	hideError() {
		this.setState({msg: ''});
	}

	async getTokenAndSendData(formData) {
		await window.grecaptcha.ready(async () => {
			const token = await Promise.resolve(window.grecaptcha.execute('6LciP68ZAAAAAPYLrM0yiYxTwt-QtDjUF7Gxxbry', {action: 'login'}));
			formData["grecaptcha_token"] = token;
			let msg = '', result = null;
			try {
				result = await login(formData);
			} catch (error) {
				console.error(error)	
			}
			if (result && result.success) {
				this.context.closeDialog('login');
			} else {
				msg = result && result.data;
				if (typeof result.data === 'object') {
					msg = JSON.stringify(msg);
					console.error(msg);
				}
				this.context.setMsg(msg);
			}
			this.props.action(result);
		});
	}
	
	async login(event){
		event.preventDefault();
	    let info = {
	    	email: this.state.email,
	    	password: this.state.password
		};
		await this.getTokenAndSendData(info);	
	 }

	render() {
		const type = this.state.showPassword ? 'text' : 'password';
		return (
			<form onSubmit={this.login}>
				<fieldset>
					<div className='fields'>
						<div className="field email">
							<label htmlFor="email">E-mail:</label>
							<input 
								name='email' 
								id='email'
								type='email'
								required
								autoComplete="username"
								placeholder="Insira seu e-mail"
								value={this.state.email} 
								onChange={this.handleInputChange} 
							/>
						</div>
						<div className="field password">
							<label htmlFor="password">Senha:</label>
							<input 
								name='password' 
								id='password'
								type={type}
								required
								placeholder="Insira sua senha"
								autoComplete="current-password"
								value={this.state.password} 
								onChange={this.handleInputChange} />
							<button type='button' className={'togglepass '+type} onClick={this.togglePassword}></button>
						</div>
					</div>
				</fieldset>
				<footer>
					<div className="buttons">
						<button type="submit">
							<span>Entrar</span>
						</button>
						<button type="button" className="link forget-password" onClick={this.forgetPassword}>
							<span>Esqueci minha senha</span>
						</button>
						<button type="button" className="link signup" onClick={this.signup}>
							<span>Criar uma conta</span>
						</button>
					</div>
				</footer>
				<Msg text={this.state.msg} onClose={this.hideError} />
			</form>
		);
	}
}

export default Formlogin;