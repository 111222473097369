import React from "react";
import GlobalContext from '../globalContext.js';
import Avatar from "./avatar";
class Participants extends React.Component {

	constructor(props) {
		super(props);
		this.state = {};
	}
	
	static contextType = GlobalContext;
	
	componentDidMount() {
		this.data = this.context.data;
	}

    openParticipant(id) {
		document.location.href = '/participant/' + id;
	}

	render() {
        const data = this.props.data;
		return (
		<ul className="participants">
			{data.responsible && (<li className="responsible" title="Responsável">
				<Avatar participant={data.responsible} key={data.responsible.id} />
			</li>)}
			{data.participants && data.participants.filter(o => o.id !== data.responsible.id).map(
				p => p.id ? <li key={p.id}><Avatar key={p.id} participant={p}/></li> : '')}
		</ul>
		);
	}
}

export default Participants;