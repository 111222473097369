import React from "react";
import { Link, withRouter } from 'react-router-dom';
import { getUserData } from "../utils/JWTAuth";
import GlobalContext from '../globalContext.js';
import { Trans } from "react-i18next";
import Avatar from "../components/avatar.js";
import { getOrganizations, patchOrganization } from "../utils/service";
import DialogSearch from "./dialogSearch";

class Top extends React.Component {

    constructor(props) {
		super(props);

		this.toggleNavMenu = this.toggleNavMenu.bind(this);
		this.hideNavMenu = this.hideNavMenu.bind(this);
		this.Nav = this.Nav.bind(this);
		this.toggleOrganizations = this.toggleOrganizations.bind(this);
		this.Search = this.Search.bind(this);
		this.openSearch = this.openSearch.bind(this);

		this.path = window.location.pathname.split("/")[1];
		
		this.state = {
			showingNavMenu: false,
			userData: getUserData(),
			search: '',
            organizations: [],
            showingOrganizations: false,
            organization: null
		};
	}

    static contextType = GlobalContext;

    async componentDidMount() {
        this.setState({ organization: this.context.organization});
        const organizations = await getOrganizations();
        if (organizations.success) {
            this.setState({ organizations: organizations.data });
        }
    }
	
    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            //console.log("on route change");
            this.hideNavMenu();
            this.context.closeDialog();
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    toggleNavMenu() {
		this.setState({
			showingNavMenu: !this.state.showingNavMenu
		})
	}

	hideNavMenu(e){
        let show = false;
		if(e && e.relatedTarget){
            const classList = e.relatedTarget.parentElement.classList;
            if (classList.contains('organization') && classList.contains('selected')) {
                show = true;
            } else {
                e.relatedTarget.click();
                show = false;
            }
		}
		this.setState({showingNavMenu: show});
	}

    async changeOrganization(id) {
        let formData = new FormData();
		formData.append('id_user', this.state.userData.id);
        const result = await patchOrganization(formData, id);
        if (result.success) {
            this.context.loadPec();
            this.context.insertCSS(id);
            this.toggleOrganizations();
            this.hideNavMenu();
            this.props.history.push('/');
        } else {
            console.error(result.data);
        }
    }

    toggleOrganizations() {
        this.setState({
            showingOrganizations: !this.state.showingOrganizations  
        });
    }

    Nav() {
        const organization = this.context && this.context.organization;
        if (!organization) {
            return '';
        }
		const userData = this.props.userData;
        const classItem = (path) => path + (path === this.path ? ' selected' : '');
        return (
            <nav className={this.state.showingNavMenu ? 'opened' : 'closed'}>
                
                <button className="menu"
                    title="Abrir o menu de navegação"
                    onClick={this.toggleNavMenu}
                    onBlur={this.hideNavMenu}
                ></button>

                {this.state.showingNavMenu && (
                <ul>
                    {(this.props.logged) && (<>
                    <li className={classItem('account')}
                        title={'Entrou como ' + userData.username + ' (' + userData.role + ')'}
                    >
                        <Link to="/account">
                            <Avatar participant={userData} />
                            <span>{userData.participant.nickname}</span>
                        </Link>
                    </li>
                    {(this.state.organizations.length > 1) && (
                    <li className={"organization selected" + (this.state.showingOrganizations ? ' showing' : '')}>
                        <button onClick={this.toggleOrganizations}>
                            <img src={'images/organization/'+organization.logo} alt="" />
                            <span>{organization.name}</span>
                        </button>
                    </li>)}
                    </>)} 
                    {(this.state.organizations.length > 1) && this.state.organizations.filter(x => x.id !== this.context.organization.id).map(o => {
                        return (
                        <li key={o.id} className={"organization" + (this.state.showingOrganizations ? '' : ' hide')}>
                            <button onClick={()=>this.changeOrganization(o.id)}>
                                <img src={'images/organization/'+o.logo} alt="" />
                                <span>{o.name}</span>
                            </button>
                        </li>
                        )
                    })}
                    <li className={classItem('pec')}>                        
                        <Link to="/pec">Etapas do PEC</Link>
                    </li>
                    <li className={classItem('participants')}>
                        <Link to="/participants">Participantes</Link>
                    </li>
                    <li className={classItem('help')}>
                        <Link to="/help">Ajuda</Link>
                    </li>
                    
                    {this.props.logged && (<li className='logout'>
                        <button className="logout" onClick={this.props.doLogout}>Sair (logout)</button>
                    </li>)}
                    {!this.props.logged && (<li className='login'>
                        <button type="submit"
							onClick={() => this.context.openDialog('login')}
							title='Entre para acessar sua conta'
						>   
							<span><Trans i18nKey="enter">Entrar</Trans></span>
						</button>
                    </li>)}
                </ul>)}
            </nav>
        );
    }

    openSearch() {
        
        this.context.openDialog({
            name: 'searchDialog',
            title: 'Busca',
            modal: false,
            content: <DialogSearch
                text={this.state.text}
            />
        });

    }

    Search() {
        return (
            <div className="search">
                <button onClick={this.openSearch}></button>
            </div>
        );
    }

    Footer() {
        return (
            <footer>
                <button className="calendar" onClick={()=>document.location.href='/calendar'}></button>
                <button className="main" onClick={()=>document.location.href='/'}></button>
                <button className="chat" onClick={()=>document.location.href='/conversations'}></button>
            </footer>
        );
    }

    render() {
        return (<>
            <this.Nav />
            <this.Search />
            <this.Footer />
        </>);
    }
}

export default withRouter(Top);