import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../components/avatar";
import { formatDate } from "../utils/convert";
import { getMessages } from "../utils/service";

export default class Conversations extends React.Component {

    constructor (props) {
        super (props);

        this.state = {
            items: [],
            msgError: ''
        }
    }

    async componentDidMount() {

        const messages = await getMessages();
        if (messages.success) {
            this.setState({ items: messages.data });
        } else {
            this.setState({ msgError: "Não foi possível carregar as menaagens. " + messages.data })
        }
    }

    render() {
        console.log(this.state)
        return (<main className="conversations">
            <header>
                <h2>Messages</h2>
            </header>
            <h3>Tarefas com mensagens</h3>
            <ul>
            {this.state.items.map(o => (
            <li key={o.id_task} className={'card conversation ' + o.type}>
                <Link to={'/task/'+o.id_task}>
                    <h4>
                        <span>{o.name}</span>
                        <small className="badge">{o.total}</small>
                    </h4>
                    <div>
                        <Avatar participant={o.author} />
                        <p>{o.text}</p>
                        <time>{formatDate(o.date_message, 'short')}</time>
                    </div>
                </Link>
            </li>))}
            </ul>
        
            {this.msgError && (<div className="error">
                {this.state.msgError}
            </div>)}
        </main>)
    }
}