import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../utils/convert";
import { getCalendar } from "../utils/service";

export default class Calendar extends React.Component {

    constructor (props) {
        super (props);

        this.state = {
            items: [],
            msgError: ''
        }
    }

    async componentDidMount() {

        const calendar = await getCalendar();
        if (calendar.success) {
            console.log(calendar.data); 
            this.setState({ items: calendar.data });
        } else {
            this.setState({ msgError: "Não foi possível carregar o caledário." + calendar.data })
        }
    }

    render() {
        const link = (o) => {
            let l = 'task/';
            if (o.type === 'step') {
                l = 'pec#'
            }
            return l + o.link;
        }
        const tag = (type) => {
            const text = type === 'step' ? 'Etapa' : 'Tarefa';
            return <span className="tag">{text}</span>
        }
        return (<main className="calendar">
            <header>
                <h2>Calendário</h2>
            </header>
            <ul>
                {this.state.items.map(o => (
                <li key={o.type+o.link} className={o.type}>
                    {tag(o.type)}
                    <Link to={()=>link(o)}>
                        <h4>{o.title}</h4>
                        <time>{formatDate(o.date)}</time>
                    </Link>
                </li>))}
            </ul>
            {this.msgError && (<div className="error">
                {this.state.msgError}
            </div>)}
        </main>)
    }
}