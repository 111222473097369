import React from "react";
import Avatar from "../components/avatar.js";
import DialogChat from "../components/dialogChat.js";
import { handleChange, InputDate, InputRadio, InputText, pickFields, Select, Textarea, updateFields } from "../components/fields.js";
import Message from "../components/message.js";
import GlobalContext from '../globalContext.js';
import { formatDate } from "../utils/convert.js";
import { getUserData } from "../utils/JWTAuth.js";
import { setItem } from "../utils/service.js";
class Task extends React.Component {

	constructor(props) {
		super(props);
        this.Header = this.Header.bind(this);
		this.openChat = this.openChat.bind(this);
		this.updateMessages = this.updateMessages.bind(this);
        this.toggleForm = this.toggleForm.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showError = this.showError.bind(this);
		this.showSuccess = this.showSuccess.bind(this);
		this.hideError = this.hideError.bind(this);
		this.updateObj = this.updateObj.bind(this);

        this.fieldNames = ['name', 'details', 'id_status', 'cost', 'start_time', 'end_time', 'done', 'id_user'];
        this.id = parseInt(document.location.pathname.split("/")[2]);
        this.identifier = "fieldsTask_" + this.id;
        
		this.state = {
            task: {},
            fields: pickFields(null, this.fieldNames),
			form: false
        };
	}
	
	static contextType = GlobalContext;
	
	async componentDidMount() {
		document.title = "Planejamento Estratégico Contínuo - Tarefa";
		this.data = this.context.data;
		let organization = this.context.data;

        if (!(organization && organization.task && organization.task.id)) {
            if (this.id) {
                organization = await this.context.setTask(this.id);
                console.log(organization)
            }
        }
        if (organization.task && organization.task.id) {
            const id = organization.task.id;
            let fields = pickFields(organization.task, this.fieldNames);
            fields = updateFields(this.identifier, fields);

            this.setState({
                task: organization.task,
                fields,
                id
            });
        }
	}

	Header({task}) {
        const dateOptions = {
            day: "2-digit",
            month: "2-digit"
        };
        const classes = ["expectations"];
        if (task && task.done) classes.push("done");
        if (task && (!task.done && task.elapsed > task.days)) classes.push("overdue");
		return (<header>
            <h2>Tarefa</h2>

            {task && (<>
			<figure>
				<img src={'/images/status/'+task.status.icon} alt="" />
			</figure>
            <h1>{task.name}</h1>
            <p>
                {task.details}
            </p>
			<div className={classes.join(" ")}>
                <label>Estimativa de entrega</label>
                <progress value={task.elapsed} max={task.days}></progress>
                <time dateTime={task.start} title={formatDate(task.start_time)}>{formatDate(task.start_time, dateOptions)}</time>
                <time dateTime={task.end} title={formatDate(task.end_time)}>{formatDate(task.end_time, dateOptions)}</time>
                <b>{task.days + ' dia' + (task.days === 1 ? '' : 's')}</b>
			</div>

            <div className="information">
				<div className="responsible" title="Responsável pela tarefa">
                    <label>Responsável</label>
                    <Avatar participant={task.responsible} />
                    <b>{(task.responsible.participant && task.responsible.participant.nickname) || 'Não atribuído'}</b>
				</div>
				<div className="situation" title="Situação da tarefa">
                    <label>Situação</label>
					<img src={'/images/status/'+task.status.icon} alt="" />
                    <b>{task.status.name}</b>
				</div>

            </div>
            </>)}
			{!task && (<>
            <p className="empty">
                A tarefa não foi encontrada.
            </p>
            </>)}

        </header>

		);
	}

    async updateMessages(m) {
        let task  = this.state.task;
        task.messages = m;
        this.setState({ task });
    }

    openChat() {
        const task = this.state.task;
        const data = getUserData();
        if (data.id) {
            this.context.openDialog({
                name: 'chatDialog',
                title: 'Mensagens',
                modal: false,
                content: <DialogChat 
                    task={task}
                    close={this.updateMessages}
                    setOption={this.setOption}
                    optionSelected={this.state.optionSelected}
                    />
            });
        } else {
            this.context.openDialog('login');
        }
    }

    async updateObj(name) {
		let obj = this.context.data;
        if (!(obj && obj[name])) {
			if (this.id) {
				let functionName = 'set' + name[0].toUpperCase() + name.substring(1);
                return await this.context[functionName](this.id);
            }
        }
	}
	
	async handleSubmit(event) {
		event.preventDefault();
		this.context.setLoading(true);

		const id = this.id;
		const form = event.target;
		const formData = new FormData(form);
		
		try {			
			const result = await setItem(formData, id, 'tasks');
			if (!result.success) {
				return this.showError(result.data || 'Não foi possível salvar as informações.');
			}
		} catch (error) {
			console.error("Erro\n", error);
			return;
		}

		localStorage.removeItem(this.identifier);
		
		this.setState({
			form: false,
			showConfirm: true
		});

		await this.context.loadPec();
		this.context.setLoading(false);
		// return this.showSuccess('Seus dados foram salvos');
	}


	showError(msg) {
		this.setState({msg: '', msgType: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	showSuccess(msg) {
		this.setState({msg: '', msgType: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	hideError(msg) {
		this.setState({msg: ''});
	}


	handleChange(event) {
		return handleChange(event, this);
	}

    toggleForm() {
		this.setState({ form: !this.state.form })
	}

	render() {
        const task = this.context.organization && this.context.organization.task;

		if (!task) {
            return <p className="empty">Tarefa não encontrada.</p>;
		}
        const hasResponsible = !!(task && task.responsible.participant);
		return (
			<main className="task">
                {!this.state.form && (<>
				<this.Header task={task} />

                {task && (<>

                <h3>Interação</h3>
				{task.messages.length > 0 && (<div className="messages">
                    {task.messages.map((o, i) => { 
                        o["sameAuthor"] = task.messages[i + 1] && (task.messages[i + 1].author.id === o.author.id);
                        o["my"] = (getUserData().id === o.author.id);
                        return <Message message={o} key={o.id} />
                    })}
                </div>)}
				{(hasResponsible && task.messages.length) === 0 && (<div className="no messages">
                    <p>
                        Nenhuma mensagem foi escrita ainda. 
                    </p>
                    <p>
                        Mantenha esta tarefa viva.
                    </p>
                    <p>
                        Seja o primeiro a interagir!
                    </p>
                </div>)}
				{(!hasResponsible && task.messages.length) === 0 && (<div className="no messages">
                    <p>
                        Não é possível interagir por que não há um responsável por esta tarefa. 
                    </p>
                    <p>
                        Solicite ao responsável pela ação desta tarefa que nomeie um responsável.
                    </p>
                </div>)}
                {hasResponsible && (<button type="button" onClick={this.openChat}>
                    <span>Escrever uma mensagem</span>
                </button>)}

                <footer className="extras">
                    <button onClick={this.toggleForm} type="button" className="edit"><span>Editar esta tarefa</span></button>
                </footer>

                </>)}
                </>)}
                {this.state.form && (<>
                    <header>
						<h2>Editar</h2>
						<h1>Tarefa</h1>
						<h4>Altere as informações de acordo com o que foi estabelecido nos workshops.</h4>
					</header>

					{this.state.fields && (
					<form onSubmit={this.handleSubmit} encType="multipart/form-data" method="post">
						<InputText 
                            label='Nome'
                            name="name"
                            placeholder="Digite o nome da ação"
                            value={ this.state.fields.name }
                            changeValue={ this.handleChange }
                            required={true}
                            instructions={ "Nome curto e fácil de entender para esta ação" } 
                            pattern={ null }
                        />
                        <Textarea
                            label='Detalhes'
                            name="details"
                            placeholder="Detalhamento da tarefa"
                            value={ this.state.fields.details }
                            changeValue={ this.handleChange }
                            required={false}
                            instructions={ "Escreva as etapas necessárias para executar a tarefa." } 
                        />
                        <InputDate
                            label='Início'
                            name="start_time"
                            placeholder="Data de início"
                            value={ this.state.fields.start_time.replace(' 00:00:00', '') }
                            changeValue={ this.handleChange }
                            required={true}
                            instructions={ "Defina a data prevista para início de execução desta tarefa." } 
                            pattern={ null }
                        />
                        <InputDate
                            label='Conclusão'
                            name="end_time"
                            placeholder="Data de término"
                            value={ this.state.fields.end_time.replace(' 00:00:00', '') }
                            changeValue={ this.handleChange }
                            required={true}
                            instructions={ "Defina a data de previsão de término desta tarefa. " + this.state.fields.end_time } 
                            pattern={ null }
                        />
                        <InputRadio
                            label='Concluído'
                            name="done"
                            value={ this.state.fields.done }
                            changeValue={ this.handleChange }
                            options={[{ name: 'Sim', value: '1' },{ name: 'Não', value: '0' }]}
                            instructions="Informe se esta tarefa já foi concluída." 
                        />
                        <Select
                            label='Responsável'
                            name="id_user"
                            placeholder="Selecione um responsável"
                            value={ this.state.fields.id_user }
                            changeValue={ this.handleChange }
                            options={this.context.organization.participants.map(o => ({ name: o.participant.nickname + ' (' + o.participant.fullname + ')', value: o.id}))}
                            instructions="Selecione o responsável por esta ação entre os participantes." 
                        />
						<div className="buttons">
							<button type="submit">Salvar</button>
							<button type="button" onClick={this.toggleForm}>Voltar</button>
						</div>
					</form>
					)}
                </>)}
			</main>
		);
	}
}

export default Task;