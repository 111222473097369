import React from 'react';
import { withRouter } from "react-router-dom";
import { getUsers } from "../utils/service.js";
import { isLogged, getUserData } from "../utils/JWTAuth.js";
import Msg from '../components/msg';
import GlobalContext from '../globalContext.js';
import Avatar from '../components/avatar.js';

class User extends React.Component {
	constructor(props) {
		super(props);

		this.showError = this.showError.bind(this);
		this.hideError = this.hideError.bind(this);		
		this.seePhoto = this.seePhoto.bind(this);		
		this.cancelSeePhoto = this.cancelSeePhoto.bind(this);		
		this.magnifyPhoto = this.magnifyPhoto.bind(this);		

		this.userData = getUserData();
        this.props.setLoading(true); 
		this.id = this.props.location.pathname.split("/")[2];
		
		this.state = {
			user: {}
		};

	}
    
	static contextType = GlobalContext;
	
	async componentDidMount() {
		console.log(this.id);	
		document.title = "Participantes";
		if (isLogged()) {
			let result = await getUsers(this.id);
			if (result && result.success) {
				this.setState({user: result.data });
			}
			if (!result || !result.success) {
				this.showError("Usuário não localizado.");
			}
		} else {
			this.showError("Faça login para acessar a lista de usuários cadastrados.");
		}
	    this.props.setLoading(false); 
	}


	showError(msg) {
		this.setState({msg: msg});
	}
	

	hideError(msg) {
		this.setState({msg: ''});
	}
	

	seePhoto() {
		let src = this.state.user.participant && this.state.user.participant.avatar;
		if (src) {
			this.setState({mode: 'photo'});
		}
	}

	cancelSeePhoto() {
		this.setState({mode: 'view'});
	}

	magnifyPhoto() {
		let m = this.state.magnifiedPhoto;
		if (!m) {
			this.setState({magnifiedPhoto: true})
		}
		if (m) {
			this.setState({
				magnifiedPhoto: false,
				mode: 'view'
			})
		}
	}
	

	render() {
		const user = this.state.user;
		return (
		<main className="participants">
			<header>
				<h2>Participante</h2>
			</header>
			<section>
				{user.participant && (<>

				{this.state.mode === 'photo' && (<>
				<div className={'seephoto' + (this.state.magnifiedPhoto ? ' magnified' : '')}>
					<img alt='' onClick={this.magnifyPhoto} src={'/images/participant/' + user.participant.avatar} />
				</div>
				</>)}

				<Avatar participant={user} action={this.seePhoto} />
				<h1>
					<span>{user.participant.nickname}</span>
					<small>{user.fullname}</small>
				</h1>
				<h4>
					<label>E-mail</label>
					<span>{user.email}</span>
				</h4>
				<h4>
					<label>Telefone</label>
					<span>{user.tel}</span>
				</h4>
				<h4>
					<label>Cep</label>
					<span>{user.postalcode}</span>
				</h4>
				</>)}
				{!user.participant && (
					<p>Usuário não localizado.</p>
				)}
			</section>
			<Msg text={this.state.msg} onClose={this.hideError} />
		</main>
		);
	}
}

export default withRouter(User);