import React from "react";
import Msg from "../components/msg";
import GlobalContext from "../globalContext";
import { formatDate } from "../utils/convert";
import { addTicket, getTickets, patchResolved } from "../utils/service";


export default class Help extends React.Component {

    constructor (props) {
        super(props);

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.showError = this.showError.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.hideError = this.hideError.bind(this);
        this.loadTickets = this.loadTickets.bind(this);
        this.Ticket = this.Ticket.bind(this);
        this.changeMode = this.changeMode.bind(this);
        this.choiceResolved = this.choiceResolved.bind(this);

        this.textarea = React.createRef();

        this.state = {
            msg: '',
            question: '',
            tickets: [],
            newTicketId: 0,
            mode: 'view'
        }
        this.props.setLoading(true); 
    }

    static contextType = GlobalContext;

    async componentDidMount() {
        document.title = "Ajuda do PEC";
        await this.loadTickets();
        this.props.setLoading(false); 
	}
    
    async loadTickets() {
        const tickets = await getTickets();
        if (tickets.success) {
            this.setState({
                tickets: tickets.data,
                mode: tickets.data.length > 0 ? 'view' : 'form'
            });
        }
    }

    showError(msg) {
		this.setState({msg: '', type: 'error'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	
	showSuccess(msg) {
		this.setState({msg: '', type: 'success'});
		setTimeout(()=>this.setState({msg: msg}), 1);
	}
	
	hideError(msg) {
		this.setState({msg: ''});
	}

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    changeMode(mode) {
        if (mode === 'form') {
            setTimeout(() => this.textarea.current.focus(), 300);
        }
        this.setState({mode});
    }

	async handleFormSubmit(event) {

		console.log(event);
		this.props.setLoading(true);
		event.preventDefault();

		const form = event.target;
		const formData = new FormData(form);
        const organization = this.context.organization && this.context.organization;
        formData.append('id_organization', organization['id']);
		const result = await addTicket(formData);
		if (!result.success) {
			return this.setState({ 
                msg: {
                    text: 'Não foi possível enviar a mensagem.',
                    type: 'error'
                }
            });
		}
		this.setState({
			msg: {text: 'Mensagem enviada.', type: 'success'},
            question: '',
            newTicketId: result.data[0].id
		});
        console.log(result.data[0].id, result.data)
        this.loadTickets();
		this.props.setLoading(false);
	}

    async choiceResolved(option, ticket) {
        if (ticket.resolved === option) {
            return;
        }
		this.props.setLoading(true);
        let id = ticket.id;
        let formData = new FormData();
		formData.append('resolved', option);
        const result = await patchResolved(formData, id);
        if (result.success) {
            this.showSuccess('Obrigado pelo feedback!')
        } else {
            this.showError('Falha ao enviar os dados.')
        }
        const tickets = this.state.tickets.map(o => {
            if (o.id === ticket.id) {
                o.resolved = result.data.resolved;
            }
            return o;
        });
        this.setState({ 
            loading: false,
            tickets
        });
    }

    Ticket({ticket}) {
        const newTicket = this.state.newTicketId === ticket.id;
        const classlist = [];
        newTicket && classlist.push('new');
        ticket.resolved && classlist.push('resolved');
        return (<li className={classlist.join(" ")}>
            <h4>{ticket.question}</h4>
            <div className="dateCreated">
                <span>Questão enviada em:</span> <time>{formatDate(ticket.date_created, 'short')}</time>
            </div>
            {!!ticket.responses.length && (<>
                {ticket.responses.map(r => (
                <div className="response" key={r.id}>
                    <p>{r.response}</p>
                    <div className="dateCreated">
                        <span>Resposta enviada em:</span> <time>{formatDate(r.date_created, 'short')}</time>
                    </div>
                    <div className="resolved">
                        <span>Esta resposta resolveu a questão?</span>
                        <div className='buttongroup choice'>
                            <button type="button" onClick={()=>this.choiceResolved(false, ticket)} className={'bad' + (ticket.resolved ? '' : ' selected')}>Não</button>
                            <button type="button" onClick={()=>this.choiceResolved(true, ticket)} className={'good' + (ticket.resolved ? ' selected' : '')}>Sim</button>
                        </div>
                    </div>
                </div>
                ))}
            </>)}
            {!ticket.responses.length && (
                <div className="response empty">
                    <span>Aguardando resposta do suporte.</span>
                </div>
            )}
        </li>);
    }

    render() {
        const tickets = this.state.tickets;
        return (
            <main className="help">
                <header>
                    <h2>Ajuda</h2>
                    <h1>
                        Precisa de ajuda?<br /> 
                    </h1>
                    <p>
                        Nossa equipe vai responder o mais rápido possível.
                    </p>
                </header>
                {this.state.mode === 'view' && (
                    <button type="button" onClick={()=>this.changeMode('form')}>Enviar uma nova mensagem</button>
                )}
                {this.state.mode === 'form' && (

                <form onSubmit={this.handleFormSubmit}>
                    <div className="fields">
                        <div className="field">
                            <label htmlFor="question">Mensagem:</label>
                            <textarea 
                                id="question"
                                name="question"
                                ref={this.textarea}
                                onChange={this.handleInputChange}
                                value={this.state.question}
                                disabled={this.props.loading}
                                placeholder="Escreva sua dificuldade ou dúvida sobre o PEC"
                                required title="Escreva uma mensagem pra nossa equipe de atendimento" 
                            />
                            {this.state.msg && (<p className={this.state.msg.type}>{this.state.msg.text}</p>)}
                        </div>
                        <div className="buttons">
                            <button type="submit" disabled={this.props.loading}>Enviar</button>
                            <button type="button" onClick={()=>this.changeMode('view')}>Cancelar</button>
                        </div>
                    </div>
                </form>
                )}

                {tickets &&(<>
                <h3>Mensagens enviadas</h3>
                <ul className="tickets">
                    {tickets.map(o => <this.Ticket key={o.id} ticket={o} />)}
                </ul>
                </>)}
                
                <div className="about">
                    <div className="channels info">
                        <label>Outros canais de atendimento:</label>
                        <p>
                            E-mail: <a href="mailto:suporte@sempre.design" target="_blank" rel="noopener noreferrer">suporte@sempre.design</a>
                        </p>
                        <p>
                            WhatsApp: <a href="https://wa.me/5527997189868?text=Preciso%20de%20ajuda%20com%20o%20*PEC*">Enviar mensagem</a>
                        </p>
                    </div>
                        <h3>
                            <b>PEC</b><br />
                            <small>Planejamento Estratégico Contínuo.</small>
                        </h3>
                    <p>
                        <small>
                            Desenvolvido com <span role='img' aria-label='love'>❤️</span> pela <a href="https://sempre.design">Sempre Design</a>
                        </small>
                    </p>
                </div>
				{this.state.msg && <Msg text={this.state.msg.text} type={this.state.msg.type} onClose={this.hideError} />}
            </main>
        )
    }

}