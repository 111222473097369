import React from "react";
import GlobalContext from '../globalContext.js';
import { getUserData } from "../utils/JWTAuth.js";
import { getSearch } from "../utils/service.js";

export default class DialogSearch extends React.Component {

    constructor(props) {
        super(props);
        this.handleChangeText = this.handleChangeText.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.sendSearch = this.sendSearch.bind(this);

        this.state = {
            loading: false,
            items: [],
            notFoundMessage: 'Pesquise em comissões, ações, tarefas e mensagens.'
        };

        this.text = React.createRef();
    }


    static contextType = GlobalContext;


    async componentDidMount() {
        this.text.current.focus();
    }


    handleChangeText = (event) => {
        if (event.key) {
            console.log(event.key)
        }
        this.context.setSearch(event.target.value);
    }

    
    handleKeyDown(e) {
        if(e.key === 'Enter' && !e.shiftKey) {
            this.sendSearch();
        }
    }


    async sendSearch(event) {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (this.context.search === '') {
            return;
        }
        this.setState({ loading: true });
        const data = getUserData();
        if (data.id) {
            const result = await getSearch(this.context.search);
            if (result.success) {
                this.setState({ items: result.data });
            } else {
                this.setState({ 
                    items: [],
                    notFoundMessage: 'Sua pesquisa não encontrou nenhum resultado correspondente'
                });

            }
        } else {
            this.context.openDialog('login');
        }
        this.setState({ loading: false });
    }



    render() {
        const items = this.state.items;
        const type = o => {
            switch(o.type) {
                case "commission": return "Comissão";
                case "action": return "Ação";
                case "task": return "Tarefa";
                case "message": return "Mensagem";
                default: return "";
            }
        };
        const link = o => {
            switch(o.type) {
                case "commission": return "/commission/" + o.id;
                case "action": return "action/" + o.id;
                case "task": return "task/" + o.id;
                case "message": return "task/" + o.id;
                default: return "";
            }
        };
        return (
            <>
                <form onSubmit={this.sendSearch} ref={this.form}>

                    <div className='field justification'>
                        <input type="search" 
                            ref={this.text}
                            name="text"
                            id="text"
                            value={this.context.search} 
                            placeholder='Procurar por...'
                            onChange={this.handleChangeText}
                            onKeyDown={this.handleKeyDown}
                            required='required'
                        />
                    </div>

                </form>

                {items.length === 0 && (
                    <p className="empty">{this.state.notFoundMessage}</p>
                )}
                {items.length > 0 && (<>
                <h3>Resultado da busca</h3>

                <ul>
                    {items.map(o => (
                    <li className="card" key={o.type+o.id}>
                        <a href={link(o)}>
                            <span className="tag">{type(o)}</span>
                            <h4>{o.title}</h4>
                        </a>
                    </li>
                    ))}
                </ul>
                </>)}
            </>
        );
    }

}
